(function () {
    'use strict';

    angular.module('it.directives').directive('cart', cart);

    function cart() {
        return {
            restrict: 'EA',
            templateUrl: 'js/templates/directives/cart.html',
            controller: CartController,
            controllerAs: 'vm',
            bindToController: true
        }
    }

    CartController.$inject = [
        '$scope',
        '$localStorage',
        'ConfigService',
        'CheckoutService',
        'NavService'
    ];

    function CartController(
        $scope,
        $localStorage,
        ConfigService,
        CheckoutService,
        NavService) {

        var vm = this;


        vm.goToOrder = goToOrder;
        vm.CheckoutService = CheckoutService;
        vm.ConfigService = ConfigService;

        // ---

        init();

        function init() {
            watchCartItems();
        }

        function watchCartItems() {
            $scope.$watch(function() {
                return ConfigService.getConfigs();
            }, function(newValue, oldValue) {
                // if (newValue != oldValue) {
                    CheckoutService.update();
                // }

                // save configs only when configs present
                if (newValue.length) {
                    $localStorage.savedConfigurations.cart = newValue;
                    // console.log("setConfig:", newValue, "lastItem.coords:", newValue[0].items[newValue[0].items.length - 1].coords);
                }

            }, true);
        }

        function goToOrder() {
            NavService.goToOrder();
        }
    }
})();