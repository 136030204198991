(function () {
    'use strict';

    angular.module('it.directives').directive('itemImage', itemImage);

    function itemImage() {
        return {
            restrict: 'A',
            controller: ItemImageController,
            controllerAs: 'vm',
            bindToController: true,
            scope: {
                item: '='
            }
        }
    }

    ItemImageController.$inject = [
        '$rootScope',
        '$element',
        '$timeout'
    ];

    function ItemImageController(
        $rootScope,
        $element,
        $timeout) {
        
        var vm = this;

        init();

        function init() {
            updateWidth();

            $rootScope.$on('grid:update', function(event, data) {
                $timeout(function() {
                    updateWidth();
                });
            });
        }

        function updateWidth() {
            if (angular.isUndefined(vm.item) ||
                angular.isUndefined(vm.item.img_configurator_url)) return;

            var item = vm.item,
                offsetTop = 0,
                containerWidth = $element.closest('.Droppable__Container').width(),
                units = $element.closest('.Droppable__Row').children('.Droppable__Cell').length,
                unitWidth = containerWidth / units,
                elementWidth = Math.floor(unitWidth * item.dimensions.mm.outerWidth / 5);
                // elementWidth = Math.floor(unitWidth * item.dimensions.mm.outerWidth / 10);

            if (angular.isDefined(item.dimensions.units.offsetTop)) {
                offsetTop = unitWidth * item.dimensions.units.offsetTop;
            }

            // if (item.type == 'sink') elementWidth *= 1.1;

            $element.attr({
                src: item.img_configurator_url,
                alt: item.name
            });

            $element.css({
                'width': elementWidth + 'px',
                'margin-top': offsetTop + 'px',
            });

            var label = $element.parent().find('.label');
            if (label.length) {
                label.css({
                    'margin-top': offsetTop + 'px',
                })
            }
            
            if(!$rootScope.$$phase) $rootScope.$apply();
        }
    }

})();