(function () {
    'use strict';

    angular.module('it.directives').directive('print', print);

    function print() {
        return {
            restrict: 'EA',
            templateUrl: 'js/templates/directives/print.html',
            controller: PrintController,
            controllerAs: 'vm',
            bindToController: true
        }
    }

    PrintController.$inject = [
        '$rootScope',
        '$state',
        'ConfigService',
        'CheckoutService',
        'ScreenshotService'
    ];

    function PrintController(
        $rootScope,
        $state,
        ConfigService,
        CheckoutService,
        ScreenshotService
        ) {

        var vm = this;
        
        vm.trans = $rootScope.trans;
        
        vm.configs = [];
        vm.total = total;
        vm.getBodyType = getBodyType;
        vm.getBodySize = getBodySize;
        
        vm.cabinetImage = ScreenshotService.getCabinetImageWithIndex;
    
        vm.bodySizeUnits = [
            { name: 'XS', limit: 92 },
            { name: 'XL', limit: 192 },
            { name: 'XXL', limit: 292 }
        ];
    
        vm.bodyTypes = [
            { name: 'slim', itemIds: [ 1,2,3 ] },
            { name: 'euro', itemIds: [ 4,5 ] }
        ];
        
        // ---

        init();

        function init() {
            vm.configs = ConfigService.getConfigs();
            if (vm.configs.length == 0) $state.go('start');
        }
        
        function total() {
            return CheckoutService.total()
        }
    
        function getBodySize(index) {
            var body = ConfigService.config(index).get.body();
            if (!body) return null;
        
            var gridWidth = body.dimensions.units.width;
            var size;
        
            angular.forEach(vm.bodySizeUnits, function(bodySize) {
                if (gridWidth >= bodySize.limit) size = bodySize.name;
            });
        
            return size;
        }
    
        function getBodyType(index) {
            var body = ConfigService.config(index).get.body();
            if (!body) return null;
            var itemId = body.id;
        
            var type = $.grep(vm.bodyTypes, function(bodyType) {
                return bodyType.itemIds.indexOf(itemId) != -1;
            })[0];
        
            return type.name;
        }
    }
})();