(function () {
    'use strict';

    angular.module('it.services').factory('GridHelper', GridHelper);

    GridHelper.$inject = [
        '$rootScope',
        '$timeout',
        'CartService',
        'ItemService',
        'ConfigService',
    ];

    function GridHelper(
        $rootScope,
        $timeout,
        CartService,
        ItemService,
        ConfigService) {

        var vm = this;

        vm.gridDefaults = {
            grid: { rows: [] },
            row: { 
                cells: [],
                usable: true,
                type: null,
            },
            cell: { 
                item: null,
                temp: null,
                used: false,
                usable: true,
            }
        };

        vm.draggableIsInGrid = 0;
        vm.unbindMoveHandler = null;
        vm.lastCoords = null;

        init();

        return {
            create: create,
            setWidth: setWidth,
            clear: clear,
            getRow: getRow,
            getCell: getCell,
            hoverCellsForItem: hoverCellsForItem,
            resetHoveredCells: resetHoveredCells,
            switchRowForCoords: switchRowForCoords,
            checkSpaceForItem: checkSpaceForItem,
            getNextFreeCoordsForItem: getNextFreeCoordsForItem,
            blockCellsForItem: blockCellsForItem,
            freeCellsForItem: freeCellsForItem,
            blockCellsForFitting: blockCellsForFitting,
            resetUnusableCells: resetUnusableCells,
            removeItem: removeItem,
            addItem: addItem,
            moveItem: moveItem,
            getCoordsForItem: getCoordsForItem,
            onMove: onMove,
            draggableIsInGrid: draggableIsInGrid,
            reloadItems: reloadItems,
            autoArrange: autoArrange,
            placeItems: placeItems,
        }

        // ---

        function init() {
            onEnd();
        }

        function create(rows, cols) {

            // cols /= 2;
            var grid = angular.copy(vm.gridDefaults.grid);

            for (var i = 1; i <= rows; i++) {
                var row = angular.copy(vm.gridDefaults.row);
                row.id = i;
                
                for (var j = 1; j <= cols; j++) {
                    var cell = angular.copy(vm.gridDefaults.cell);
                    cell.id = j;
                    row.cells.push(cell);
                }
                grid.rows.push(row);
            }

            $rootScope.$broadcast('grid:create');

            return grid.rows;
        }

        function setWidth(rows, cols) {
            var diff = cols - rows[0].cells.length;

            angular.forEach(rows, function(row, rowIndex) {
                angular.forEach(row.cells, function (cell, colIndex) {
                    var coords = { rowIndex: rowIndex, colIndex: colIndex };
                    if (cell.item && 
                        colIndex + cell.item.dimensions.units.width > cols) {
                        CartService.removeItem(cell.item);
                        removeItem(rows, cell.item, coords);
                    }
                });
            });

            angular.forEach(rows, function(row, rowIndex) {
                // negative diff -> remove cells
                if (diff < 0) row.cells.splice(cols, -diff);
                // positive diff -> add cells
                else {
                    for (var i = 1; i <= diff; i++) {
                        var cell = angular.copy(vm.gridDefaults.cell);
                        row.cells.push(cell);
                    }
                }
            });

            $timeout(function() {
                $rootScope.$broadcast('grid:update');
            });
        }

        function clear(rows) {
            if (!rows) return null;

            angular.forEach(rows, function(row, rowIndex) {
                angular.forEach(row.cells, function (cell, colIndex) {
                    if (cell.item) removeItem(rows, cell.item, { rowIndex: rowIndex, colIndex: colIndex });
                });
            });

            return rows;
        }

        function getRow(rows, rowIndex) {
            if (rowIndex > rows.length - 1) return false;            
            return rows[rowIndex];
        }

        function getCell(rows, coords) {
            if (angular.isUndefined(rows) || !rows ||
                coords.rowIndex > rows.length - 1 ||
                coords.colIndex > rows[coords.rowIndex].cells.length - 1 ||
                coords.rowIndex < 0 ) return false;
                
            return rows[coords.rowIndex].cells[coords.colIndex];
        }

        function hoverCellsForItem(rows, item, coords) {
            var width = item.dimensions.units.width || 0,
                height = item.dimensions.units.height || 0,
                below = item.dimensions.units.below || 0,
                above = item.dimensions.units.above || 0;

            if (!width || !height) return;

            resetTemporaryItemForLastCoords(rows);

            if (height + below > 2) coords.rowIndex = 0;
            else if (above) coords.rowIndex = 1;

            var space = checkSpaceForItem(rows, item, coords);

            // if one row doesn't fit, try other row
            if (!space && height + below <= 2 && !above) {
                coords = switchRowForCoords(coords);
                space = checkSpaceForItem(rows, item, coords);
                if (!space) coords = switchRowForCoords(coords);
            }

            if (!space) {
                var possibleCoords = getFreeSpaceForItemNearCoords(rows, item, coords);
                if (possibleCoords) {
                    space = true;
                    coords = possibleCoords;
                }
            }

            if (space) getCell(rows, coords).temp = item;

            angular.forEach(rows, function(row, rowIndex) {
                angular.forEach(row.cells, function (cell, colIndex) {
                    var hovered = false;

                    if (rowIndex >= coords.rowIndex - above &&
                        rowIndex < coords.rowIndex + (height) + below &&
                        colIndex >= coords.colIndex &&
                        colIndex < coords.colIndex + (width)) {
                        hovered = true;
                    }

                    cell.hovered = hovered;
                    cell.space = space;
                });
            });
            
            vm.lastCoords = coords;

            return rows;
        }

        function resetHoveredCells(rows) {
            angular.forEach(rows, function(row, rowIndex) {
                angular.forEach(row.cells, function (cell, colIndex) {
                    cell.hovered = false;
                    cell.temp = null;
                });
            });
        }

        function resetTemporaryItems(rows) {
            angular.forEach(rows, function(row, rowIndex) {
                angular.forEach(row.cells, function (cell, colIndex) {
                    cell.temp = null;
                });
            });
        }

        function resetTemporaryItemForLastCoords(rows) {
            if (!vm.lastCoords) return;

            var cell = getCell(rows, vm.lastCoords);
            if (cell) cell.temp = null;
        }

        function switchRowForCoords(coords) {
            coords.rowIndex = coords.rowIndex ? 0 : 1;
            return coords;
        }

        function checkSpaceForItem(rows, item, coords) {          
            var cell = getCell(rows, coords),
                width = item.dimensions.units.width,
                height = item.dimensions.units.height,
                below = item.dimensions.units.below || 0,
                above = item.dimensions.units.above || 0;

            if (!cell || cell.used ||
                !getRow(rows, coords.rowIndex).usable) return false;

            var row = coords.rowIndex - above;
            if (row < 0) return false;

            if ((rows.length - row) < height) return false;

            // faucets fit only in first row
            if (item.type == 'faucet' && coords.rowIndex != 0) return false;
            // sinks fit only in second row
            if (item.type == 'sink' && coords.rowIndex != 1) return false;

            for (row; row <= coords.rowIndex + (height - 1) + below; row++) {
                for (var col = coords.colIndex; col <= coords.colIndex + width - 1; col++) {
                    if (row >= rows.length) break;

                    var cellData = getCell(rows, { rowIndex: row, colIndex: col });

                    if (!cellData || cellData.used) return false;
                    if (!cellData.usable) {
                        var bodyType = ConfigService.selectedConfig().get.bodyType();
                        
                        if (bodyType == 'slim' && !item.ignore_fittings_slim) return false;
                        else if (bodyType == 'euro' && !item.ignore_fittings_euro) return false;
                    }
                }
            }

            return true;
        }

        function getNextFreeCoordsForItem(rows, item) {
            var freeCoords = null;

            angular.forEach(rows[0].cells, function (cell, colIndex) {
                var rowSequence = getRowSequence(item, 0);

                angular.forEach(rowSequence, function(rowIndex) {
                    var coords = { rowIndex: rowIndex, colIndex: colIndex };

                    if (!freeCoords &&
                        checkSpaceForItem(rows, item, coords)) {
                        freeCoords = coords;
                    }
                });
            });

            return freeCoords;
        }

        function getFreeSpaceForItemNearCoords(rows, item, coords) {
            var freeCoords = null,
                threshold = 10,
                cols = rows[0].cells.length,
                width = item.dimensions.units.width,
                start = coords.colIndex,
                end = (start + threshold > cols - width ? cols - width : start + threshold),
                rowSequence = getRowSequence(item, coords.rowIndex);

            if (start + width > cols) return;

            angular.forEach(rowSequence, function(rowIndex) {
                for (var colIndex = start; colIndex <= end; colIndex++) {
                    
                    var possibleCoords = { rowIndex: rowIndex, colIndex: colIndex };

                    if (!freeCoords &&
                        checkSpaceForItem(rows, item, possibleCoords)) {
                        freeCoords = possibleCoords;
                    }
                }

            });

            return freeCoords;
        }

        function getRowSequence(item, rowIndex) {
            var height = item.dimensions.units.height,
                below = item.dimensions.units.below || 0,
                above = item.dimensions.units.above || 0,
                rowSequence;

            // space below && height 2 ? -> check only first row
            if (height + below > 2) rowSequence = [0];
            // space above ? -> check only second row
            else if (above) rowSequence = [1];
            // space below? -> try second row first
            else if (below) rowSequence = [1,0];
            // default
            else rowSequence = [ rowIndex, rowIndex ? 0 : 1 ];

            return rowSequence;
        }

        function blockCellsForItem(rows, item, coords) {
            return setCellStatusForItems(rows, true, item, coords);
        }

        function freeCellsForItem(rows, item, coords) {
            return setCellStatusForItems(rows, false, item, coords);
        }

        function setCellStatusForItems(rows, used, item, coords) {
            var width = item.dimensions.units.width,
                height = item.dimensions.units.height,
                below = item.dimensions.units.below,
                above = item.dimensions.units.above;

            if (angular.isUndefined(below)) below = 0;
            if (angular.isUndefined(above)) above = 0;
            
            if (angular.isUndefined(width) ||
                angular.isUndefined(height) ||
                !coords) return;

            for (var row = coords.rowIndex - above; row <= coords.rowIndex + (height - 1) + below; row++) {
                for (var col = coords.colIndex; col <= coords.colIndex + width - 1; col++) {
                    if (row >= rows.length) break;

                    setCellStatus(rows, used, { rowIndex: row, colIndex: col });
                }
            }

            return rows;
        }
        
        function setCellStatus(rows, used, coords) {
            getCell(rows, coords).used = used;
        }

        function blockCellsForFitting(rows, item) {
            if (!item ||
                item.type != 'fitting' ||
                angular.isUndefined(item.dimensions.units)) return;

            rows = resetUnusableCells(rows);

            var paddingLeftTop = item.dimensions.units.paddingLeftTop,
                paddingRightTop = item.dimensions.units.paddingRightTop,
                paddingLeftBottom = item.dimensions.units.paddingLeftBottom,
                paddingRightBottom = item.dimensions.units.paddingRightBottom;

            if (angular.isUndefined(paddingLeftTop)) paddingLeftTop = 0;
            if (angular.isUndefined(paddingRightTop)) paddingRightTop = 0;
            if (angular.isUndefined(paddingLeftBottom)) paddingLeftBottom = 0;
            if (angular.isUndefined(paddingRightBottom)) paddingRightBottom = 0;

            if (!paddingLeftTop && 
                !paddingRightTop &&
                !paddingLeftBottom && 
                !paddingRightBottom) return rows;

            var paddingCells = [];

            var leftTopCells = paddingLeftTop ? rows[0].cells.slice(0, paddingLeftTop) : [];
            var rightTopCells = paddingRightTop ? rows[0].cells.slice(-paddingRightTop) : [];
            paddingCells = paddingCells.concat(leftTopCells.concat(rightTopCells));

            var leftBottomCells = paddingLeftBottom ? rows[1].cells.slice(0, paddingLeftBottom) : [];
            var rightBottomCells = paddingRightBottom ? rows[1].cells.slice(-paddingRightBottom) : [];
            paddingCells = paddingCells.concat(leftBottomCells.concat(rightBottomCells));

            angular.forEach(paddingCells, function(cell) {
                cell.usable = false;
            });

            return rows;
        }

        function resetUnusableCells(rows) {
            angular.forEach(rows, function(row, rowIndex) {
                angular.forEach(row.cells, function (cell, colIndex) {
                    cell.usable = true;
                });
            });

            return rows;
        }

        function removeItem(rows, item, coords) {
            freeCellsForItem(rows, item, coords);
            getCell(rows, coords).item = null;
            
            $rootScope.$broadcast('grid:updateItems');
        }

        function addItem(rows, item, coords) {
            // if one row doesn't fit, try other row
            if (!checkSpaceForItem(rows, item, coords)) {
                coords = switchRowForCoords(coords);
                if (!checkSpaceForItem(rows, item, coords)) {
                    coords = switchRowForCoords(coords);
                    coords = getFreeSpaceForItemNearCoords(rows, item, coords);
                    if (!coords) return false;
                }
            }

            var cell = getCell(rows, coords);
            cell.item = item;

            blockCellsForItem(rows, item, coords);

            $rootScope.$broadcast('grid:updateItems');

            return true;
        }

        function moveItem(rows, item, dragCoords, dropCoords) {
            removeItem(rows, item, dragCoords);
            
            // if one row doesn't fit, try other row
            if (!checkSpaceForItem(rows, item, dropCoords)) {
                dropCoords = switchRowForCoords(dropCoords);
                if (!checkSpaceForItem(rows, item, dropCoords)) {
                    dropCoords = switchRowForCoords(dropCoords);                    
                    dropCoords = getFreeSpaceForItemNearCoords(rows, item, dropCoords);
                    if (!dropCoords) dropCoords = dragCoords;
                }
            }

            addItem(rows, item, dropCoords);
        }

        function getCoordsForItem(rows, item) {
            var coords = null;

            angular.forEach(rows, function(row, rowIndex) {
                angular.forEach(row.cells, function (cell, colIndex) {
                    if (cell.item === item) coords = { rowIndex: rowIndex, colIndex: colIndex };
                });
            });

            return coords;
        }

        function onMove(element, rows) {
            if (vm.unbindMoveHandler) vm.unbindMoveHandler();

            vm.draggableIsInGrid = 0;

            vm.unbindMoveHandler = $rootScope.$on('draggable:move', function(event, data) {
                if (!element) return;

                var draggableIsInGrid = element.find('.drag-enter').length,
                    dragElement = $(data.event.target);

                if (!dragElement.hasClass('Draggable')) dragElement = dragElement.closest('.Draggable');

                // hide draggable if element is in grid
                if (dragElement.length && dragElement.hasClass('dragging')) {
                     draggableIsInGrid ? dragElement.removeClass('in') : dragElement.addClass('in');
                }

                if (draggableIsInGrid == vm.draggableIsInGrid) return;
                
                if (!draggableIsInGrid) {
                    $timeout(function() {
                        resetHoveredCells(rows);
                        $rootScope.$apply;
                    });
                }

                vm.draggableIsInGrid = draggableIsInGrid;
            });
        }

        function onEnd() {
            $rootScope.$on('draggable:end', function(event, data) {
                $('.Draggable').addClass('in');                
                $rootScope.showBigDroppable = false;
            });
        }

        function draggableIsInGrid() {
            return vm.draggableIsInGrid;
        }

        function reloadItems(rows) {
            if (!rows) return;
            rows.map(function(row) {
                row.cells.map(function(cell) {
                    if (cell.item) {
                        cell.item = ItemService.getItem(cell.item.id, cell.item.cartIndex);
                    }
                });
            });
            return rows;
        }


        function autoArrange(rows, interior) {

            var space = true,
                rowsClone = angular.copy(rows);

            if (!interior) interior = getInterior(rows)

            clear(rowsClone);
       
            angular.forEach(interior, function(item) {  
                var coords = getNextFreeCoordsForItem(rowsClone, item);
                if (coords) addItem(rowsClone, item, coords);
                else space = false;
            });

            if (!space) return false;

            clear(rows);

            angular.forEach(interior, function(item) {
                var coords = getNextFreeCoordsForItem(rows, item);
                if (coords) addItem(rows, item, coords);
            });

            return true;
        }

        function getInterior(rows) {
            var interior = [];

            angular.forEach(rows[0].cells, function (cell, colIndex) {
                var rowSequence = [0, 1];

                angular.forEach(rowSequence, function(rowIndex) {
                    var cell = getCell(rows, { rowIndex: rowIndex, colIndex: colIndex });

                    if (cell && cell.item) interior.push(cell.item);
                });
            });

            if (interior.length == 0) {
                interior = ConfigService.selectedConfig().get.interior()
            }

            return interior;
        }

        function placeItems(rows, items) {
            clear(rows);
            items.map(function(item) {
                if (angular.isUndefined(item.coords)) item.coords = getNextFreeCoordsForItem(rows, item);
                addItem(rows, item, item.coords)
            });
        }
    }

})();