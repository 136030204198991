(function() {
    'use strict';
    
    angular.module('it.directives').directive('accessoryOverview', accessoryOverview);
    
    function accessoryOverview() {
        return {
            restrict: 'E',
            templateUrl: 'js/templates/directives/order/accessory-overview.html',
            controller: AccessoryOverviewController,
            controllerAs: 'vm',
            bindToController: true,
            scope: {
                readonly: '@',
                trans: '=',
            }
        }
    }
    
    AccessoryOverviewController.$inject = [
        '$rootScope',
        '$scope',
        'ConfigService',
        'CheckoutService'
    ];
    
    function AccessoryOverviewController(
        $rootScope,
        $scope,
        ConfigService,
        CheckoutService
    ) {
        var vm = this;
        
        vm.opened = false;
        vm.items = [];
        vm.subtotal = subtotal;
        vm.increase = increase;
        vm.increaseable = increaseable;
        vm.decrease = decrease;
        vm.decreaseable = decreaseable;
        
        init();
        
        function init() {
            if (vm.readonly) {
                vm.items = CheckoutService.data().accessory.items;
                vm.opened = true;
            } else {
                loadItems();
                watchItems();
            }
        }
        
        function loadItems() {
            vm.items = CheckoutService.data().accessory.items;
        }
        
        function watchItems() {
            $scope.$watch(function() {
                return vm.items;
            }, function() {
                CheckoutService.data().accessory = {
                    items: vm.items,
                    price: subtotal()
                };
            }, true);
        }
        
        function increase(item) {
            if (!increaseable(item)) return;
            item.amount += 1;
        }
        
        function increaseable(item) {
            return true
        }
        
        function decrease(item) {
            if (!decreaseable(item)) return;
            item.amount -= 1;
        }
        
        function decreaseable(item) {
            if (item.amount > item.minCount) {
                return true
            }
        }
        
        function subtotal() {
            return ConfigService.helper.priceForItems(vm.items);
        }
    }
})();