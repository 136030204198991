(function () {
    'use strict';
    
    angular.module('it.services').factory('CanvasDimensionsService', CanvasDimensionsService);
    
    CanvasDimensionsService.$inject = [
    ];
    
    function CanvasDimensionsService()
     {
        
        var vm = this;
        vm.innerDimensions = {};
        vm.bodyDimensions = {
            width: 0,
            height: 0
        };
        vm.scale = {}
        
        return {
            init: init,
            setScale: setScale,
            bodyDimensions: bodyDimensions,
            canvasDimensions: canvasDimensions,
            canvasWidth: canvasWidth,
            canvasHeight: canvasHeight,
            canvasSize: canvasSize,
            canvasCoordinateForItem: canvasCoordinateForItem
        };
        
        function init(canvasDimensions, bodyDimensions, scale) {
            vm.innerDimensions = canvasDimensions;
            vm.bodyDimensions = bodyDimensions;
            vm.scale = scale;
        }
        
        function setScale(scale) {
            vm.scale = scale;
        }
        
        function bodyDimensions() {
            return vm.bodyDimensions
        }
        
        function canvasDimensions() {
            return vm.innerDimensions;
        }
        
        function canvasWidth(originalWidth) {
            return vm.innerDimensions.width * originalWidth / vm.bodyDimensions.innerWidth
        }
        
        function canvasHeight(originalHeight) {
            return vm.innerDimensions.height * originalHeight / vm.bodyDimensions.height
        }
        
        function canvasSize(originalSize) {
            return {
                width: canvasWidth(originalSize.width),
                height: canvasHeight(originalSize.height)
            }
        }
        
        function canvasCoordinateForItem(item) {
            var offset = {
                x: item.dimensions.units.powerOffsetLeft,
                y: item.dimensions.units.powerOffsetTop + item.dimensions.units.offsetTop,
            };
            return canvasCoordinateForIndex(item.cabCoords, offset);
        }
        
        function canvasCoordinateForIndex(index, offset) {
            if (!index) return false;
            else return {
                x: (index.colIndex * vm.scale.width) + (offset.x * vm.scale.width),
                y: (index.rowIndex * vm.scale.height) + (offset.y * vm.scale.width)
            }           
        }
    }
    
})();