(function () {
    'use strict';

    angular.module('it.services').factory('CabService', CabService);

    CabService.$inject = [
        '$state',
        '$rootScope',
        '$timeout',
        'CartService',
        'DragService',
        'GridHelper',
        'SwalService',
        'ItemService',
        'ConfigService',
    ];

    function CabService(
        $state,
        $rootScope,
        $timeout,
        CartService,
        DragService,
        GridHelper,
        SwalService,
        ItemService,
        ConfigService) {

        var vm = this;

        vm.configs = [];
        vm.currentConfigIndex = null;

        vm.rows = null;

        vm.preset = null;
        vm.gridElement = null;
        vm.doorState = 'open';
        vm.bodyIsOpaque = false;

        return {
            init: init,
            createGridFromItem: createGridFromItem,
            clearGrid: clearGrid,
            addConfig: addConfig,
            removeConfig: removeConfig,
            prepareForConfigAtPosition: prepareForConfigAtPosition,
            resetConfig: resetConfig,
            selectConfig: selectConfig,
            configs: getConfigs,            
            addItem: addItem,
            removeItem: removeItem,
            replaceItem: replaceItem,
            moveItem: moveItem,
            rows: getRows,
            rowsForConfig: getRowsWithIndex,
            autoArrange: autoArrange,
            hoverCellsForItem: hoverCellsForItem,
            freeCellsForItem: freeCellsForItem,
            blockCellsForFitting: blockCellsForFitting,
            resetHoveredCells: resetHoveredCells,
            getCoordsForItemInConfig: getCoordsForItemInConfig,
            getCoordsForItem: getCoordsForItem,
            element: getGridElement,
            getBodySize: getBodySize,
            getBodySizeFromConfigWithIndex: getBodySizeFromConfigWithIndex,
            getBodyType: getBodyType,
            toggleDoor: toggleDoor,
            openDoor: openDoor,
            closeDoor: closeDoor,
            isDoorClosed: isDoorClosed,
            isDoorOpen: isDoorOpen,
            hasFittingOnLeftSide: hasFittingOnLeftSide,
            toggleBodyOpacity: toggleBodyOpacity,
            isBodyOpaque: isBodyOpaque,
            getOverlappingItemsForBody: getOverlappingItemsForBody,
            hideInstallations: hideInstallations,
        }

        // ---

        function init(element) {
            vm.gridElement = element;
            
            vm.bodyIsOpaque = false;
            watchItemDataLoaded();
        }

        function watchItemDataLoaded() {
            $rootScope.$on('itemData:loaded', function(event, data) { 
                vm.rows = GridHelper.reloadItems(vm.rows);
            });
        }

        function addConfig(index) {
            var body = CartService.getBodyFromConfigWithIndex(index),
                rows = GridHelper.create(2, body.dimensions.units.width);
                
            GridHelper.blockCellsForFitting(rows, CartService.getFitting());

            var config = { rows: rows };

            vm.configs[index] = config;
            return vm.configs.indexOf(config);
        }

        function removeConfig(index) {
            vm.configs.splice(index, 1);
        }
    
        function prepareForConfigAtPosition(position) {
            if (position == 1) return;
            if (position == -1) {
                vm.configs.splice(0, 0, {});
            } else if (position == 0) {
                vm.configs.splice(1, 0, {});
            }
        }

        function selectConfig(index) {
            vm.rows = vm.configs[index].rows;
            vm.currentConfigIndex = index;
        }

        function resetConfig(index) {
            vm.configs[index].rows = [];
        }

        function getConfigs() {
            return vm.configs;
        }

        function createGridFromItem(item) {
            createGrid(item.dimensions.units.width);
        }

        function createGrid(cols) {
            var rows = 2;

            // no grid present? -> create new
            if (!vm.rows) vm.rows = GridHelper.create(rows, cols);

            // grid already present? -> update
            else {
                GridHelper.setWidth(vm.rows, cols);
                GridHelper.blockCellsForFitting(vm.rows, CartService.getFitting());
            }
        }

        function clearGrid() {
            GridHelper.clear(vm.rows);
            hideInstallations();
        }

        function getRows() {
            return vm.rows;
        }
        
        function getRowsWithIndex(index) {
            if(index < 0 || vm.configs.length <= index ) return null;
            return vm.configs[index].rows
        }

        function getRow(rowIndex) {
            return GridHelper.getRow(vm.rows, rowIndex);
        }

        function getCell(coords) {
            return GridHelper.getCell(vm.rows, coords);
        }

        function addItem(item, coords) {
            return GridHelper.addItem(vm.rows, item, coords);
        }

        function removeItem(item, coords) {
            CartService.removeItem(item);
            GridHelper.removeItem(vm.rows, item, coords);
        }
        
        function replaceItem(item) {
            var space = true,
                success = false;
            
            $rootScope.incompatibleItems = [];
            $rootScope.overlappingItems = [];

            // body? -> check for incompatibilities & generate grid
            if (item.type == 'body') {
                $rootScope.incompatibleItems = CartService.getIncompatibleItemsForItem(item);
                $rootScope.overlappingItems = getOverlappingItemsForBody(item);
    
                if ($rootScope.incompatibleItems.length) {
                    $timeout(function() {
                        SwalService.push({
                            title: $rootScope.trans.configurator.notCompatible,
                            text: $('.IncompatibleItems').parent().html(),
                            html: true
                        });
                    }, 0);
                } else if ($rootScope.overlappingItems.length) {
                    if (item.dimensions.units.width < CartService.getBody().dimensions.units.width) {
                        autoArrange();
                        $rootScope.overlappingItems = getOverlappingItemsForBody(item);
                        showOverlappingItemsAlert();
                    } else {
                        showOverlappingItemsAlert();
                    }

                }
            }
    
            // fitting? -> check if items still fit
            if (item.type == 'fitting') {
                blockCellsForFitting(item);

                if (!autoArrange()) {
                    blockCellsForFitting(CartService.getFitting());
                    space = false;

                    SwalService.push({
                        title: $rootScope.trans.configurator.notEnoughSpace,
                        text: $rootScope.trans.configurator.removeItemsToProceed,
                    });
                }
            }

            if (!$rootScope.incompatibleItems.length &&
                !$rootScope.overlappingItems.length &&
                space) {
                
                if (item.type == 'body') createGridFromItem(item);
                
                CartService.addItem(item);

                success = true;
            }
    
            resetHoveredCells();

            return success;
        }

        function showOverlappingItemsAlert() {
            if ($rootScope.overlappingItems.length) {
                $timeout(function() {
                    SwalService.push({
                        title: $rootScope.trans.configurator.overlapping,
                        text: $('.OverlappingItems').parent().html(),
                        html: true
                    });
                });
            }
        }

        function moveItem(item, dragCoords, dropCoords) {
            GridHelper.moveItem(vm.rows, item, dragCoords, dropCoords);
        }

        function blockCellsForFitting(item) {
            GridHelper.blockCellsForFitting(vm.rows, item);
        }

        function freeCellsForItem(item, coords) {
            GridHelper.freeCellsForItem(vm.rows, item, coords);
        }

        function autoArrange() {
            return GridHelper.autoArrange(vm.rows);
        }

        function hoverCellsForItem(item, coords) {
            if (item.type != 'interior') return;

            GridHelper.hoverCellsForItem(vm.rows, item, coords);
            $rootScope.saveApply();
        }

        function resetHoveredCells() {
            GridHelper.resetHoveredCells(vm.rows);
            $rootScope.saveApply();
        }
    
        function getCoordsForItemInConfig(item, configIndex) {
            var rows = getRowsWithIndex(configIndex);
            return GridHelper.getCoordsForItem(rows, item);
        }
        
        function getCoordsForItem(item) {
            return GridHelper.getCoordsForItem(vm.rows, item);
        }

        function getGridElement() {
            return vm.gridElement;
        }

        function getBodySize() {
            if (angular.isDefined(ConfigService.selectedConfig())) {
                return ConfigService.selectedConfig().get.bodySize();
            }
        }

        function getBodySizeFromConfigWithIndex(index) {
            if (index < 0) return null;

            selectConfig(index);
            return getBodySize();
        }

        function getBodyType() {
            if (angular.isDefined(ConfigService.selectedConfig())) {
                return ConfigService.selectedConfig().get.bodyType();
            }
        }

        function toggleDoor() {
            vm.doorState = (vm.doorState == 'open' ? 'closed' : 'open');
        }

        function openDoor() {
            vm.doorState = 'open';
        }

        function closeDoor() {
            vm.doorState = 'closed';
        }

        function isDoorClosed() {
            return vm.doorState == 'closed';
        }

        function isDoorOpen() {
            return vm.doorState == 'open';
        }

        function hasFittingOnLeftSide() {
            var cell = getCell({ rowIndex: 0, colIndex: 0 });
            return !cell.usable;
        }

        function toggleBodyOpacity() {
            if (!vm.gridElement) return;
            
            vm.bodyIsOpaque = !vm.bodyIsOpaque;
            
            $(vm.gridElement).find('.Body').toggleClass('opaque');
        }

        function isBodyOpaque() {
            if (!vm.gridElement) return false;
            
            return vm.bodyIsOpaque;
        }

        function hideInstallations() {
            if (vm.bodyIsOpaque) toggleBodyOpacity();
        }

        function getOverlappingItemsForBody(body) {
            var maxWidth = body.dimensions.units.width,
                overlappingItems = [];

            angular.forEach(vm.rows, function(row, rowIndex) {
                angular.forEach(row.cells, function(cell, colIndex) {
                    if (!cell.item) return;

                    if (colIndex + 1 + cell.item.dimensions.units.width > maxWidth) {
                        overlappingItems.push(cell.item);
                    }
                });
            });

            return overlappingItems;
        }
        
    }

})();