(function() {
    'use strict';
    
    angular.module('it.services').factory('CheckoutService', CheckoutService);
    
    CheckoutService.$inject = [
        'ConfigService',
    ];
    
    function CheckoutService(ConfigService) {
        var vm = this;
    
        var specialItems = [
            {
                item_no: '18092',
                item: 'powerSocket'
            },
            {
                item_no: '35550',
                item: 'distributionBoard'
            },
            {
                item_no: '17042',
                item: 'inWallSocket'
            },
            {
                item_no: '18080',
                item: 'cord50'
            }
        ];
        
        vm.defaults = { 
            cabinets: [],
            power: {
                items: [],
                price: 0,
                source: 'battery'
            },
            accessory:{
                items: [],
                price: 0
            },
            washstand: {
                items: [],
                price: 0
            }
        };

        vm.data = angular.copy(vm.defaults);

        return {
            data: data,
            total: total,
            vat: vat,
            shipping: shipping,
            update: update,
            updateConfig: updateConfig,
            updatePowerItems: updatePowerItems,
            updatePowerItemsForSource: updatePowerItemsForSource,
            activeForSource: activeForSource,
            reset: reset,
        };

        function reset() {
            vm.data = angular.copy(vm.defaults);
        }
        
        function data() {
            return vm.data
        }
        
        function update() {
            reset();

            angular.forEach(ConfigService.getConfigs(), function(config, index) {
                updateConfig(index);
            });
        }
        
        function updateConfig(index) {
            var config = ConfigService.config(index),
                cartData = config.cart();

            vm.data.cabinets[index] = {
                body: config.get.body(),
                fitting: config.get.fitting(),
                front: config.get.front(),
                interior: cartData.interior,
                price: cartData.price
            };
            
            updateAccessory();
            updatePowerItems();
            updateWashstand();
        }
        
        function updateWashstand() {
            var items = ConfigService.configs.washstandItems();
            vm.data.washstand.items = ConfigService.helper.groupItems(items);
            vm.data.washstand.price = subtotal(vm.data.washstand.items);
        }
        
        function updateAccessory() {
            var items = ConfigService.configs.accessoryItems();
            vm.data.accessory = {
                items: items,
                price: subtotal(items)
            }
        }
        
        function updatePowerItems(source) {
            var items = ConfigService.configs.powerItems();
            console.log(items);
            console.log('Distribution Boards', ConfigService.configs.numberOfDistributionBoards());

            angular.forEach(items, function(item) {
                angular.forEach(specialItems, function(spec) {
                    if (item.item_no == spec.item_no) {
                        if (spec.item == 'distributionBoard') {
                            item.minCount = ConfigService.configs.numberOfDistributionBoards();
                            item.amount = item.minCount;
                        } else if (spec.item == 'cord50') {
                            item.amount = ConfigService.configs.numberOfDistributionBoards() - 1;
                        } else {
                            item.minCount = 1;
                            item.amount = 1;
                        }
                    }
                });
            });
            vm.data.power.items = items;
            updatePowerItemsForSource(source || vm.data.power.source)
        }
        
        function updatePowerItemsForSource(source) {
            updateItemAmounts(vm.data.power.source, source);
            vm.data.power.price = subtotal(vm.data.power.items);
            vm.data.power.source = source;
            return vm.data.power.items;
        }
    
        function updateItemAmounts(oldSrc, newSrc) {
            angular.forEach(vm.data.power.items, function(item) {
                if (activeForSource(item, newSrc)) {
                    if (newSrc == 'battery') {
                        item.amount = (item.battery) ? item.minCount : 0
                    } else {
                        if (oldSrc == 'battery') {
                            if (item.powerSupply) {
                                item.amount = Math.max(item.amount, item.minCount);
                            } else {
                                item.amount = 0;
                            }
                        } else if (item.minCount > item.amount) {
                            item.amount = item.minCount
                        }
                    }
                } else {
                    item.amount = 0;
                }
            })
        }
    
        function activeForSource(item, src) {
            if (angular.isUndefined(src)) {
                src = vm.data.power.source
            }
            if (src == 'battery') return item.battery;
            else if (item.item_no == '17042') {
                return src == 'inWallSocket'
            } else if (item.item_no == '18092') {
                return src == 'powerSocket'
            } else {
                return item.powerSupply;
            }
        }
        
        function total() {
            var price = 0;
            
            angular.forEach(vm.data.cabinets, function(cabinet) {
                price += cabinet.price;
            });
            
            price += vm.data.power.price;
            price += vm.data.accessory.price;
            price += vm.data.washstand.price;
            
            return price;
        }

        function vat() {
            var factor = 0.19;

            return total() * factor;
        }

        function shipping() {
            var configs = ConfigService.getConfigs(),
                price = 0,
                count = {
                    XXL: 0,
                    XL: 0,
                    XS: 0,
                };
            
            angular.forEach(configs, function(config, index) {
                var bodySize = ConfigService.config(index).get.bodySize();
                count[bodySize]++;
            });

            // each XXL = 35.00€ shipping
            price += (count.XXL * 35);

            // up to 2 XS/XL = 29.90€ shipping
            // up to 5 XS/XL = 59.90€ shipping
            // more than 5 XS/XL = 79.90€ shipping
            if (count.XS + count.XL > 0) {
                if (count.XS + count.XL <= 2) price += 29.9;
                else if (count.XS + count.XL <= 5) price += 59.9;
                else if (count.XS + count.XL > 5) price += 79.9;
            }

            return price;
        }
    
        function subtotal(items) {
            return ConfigService.helper.priceForItems(items);
        }
    }
    
})();