(function () {
    'use strict';

    angular.module('it-config', [
        'ui.router',
        'it.controllers',
        'it.directives',
        'it.services',
        'it.filters',
        'ngSanitize',
        'ngDraggable',
        'ngStorage',
        'moment-picker',
    ]);

    angular.module('it.controllers', []);
    angular.module('it.directives', []);
    angular.module('it.services', []);
    angular.module('it.filters', []);
})();