(function () {
    'use strict';

    angular.module('it.services').factory('PresetService', PresetService);

    PresetService.$inject = [
        '$q',
        '$http',
        '$state',
        'CartService',
        'CabService',
        'WashstandService',
        '$timeout',
        '$rootScope',
    ];

    function PresetService(
        $q,
        $http, 
        $state,
        CartService, 
        CabService, 
        WashstandService, 
        $timeout, 
        $rootScope) {

        var vm = this;

        vm.data = [];

        return {
            init: init,
            data: getData,
            getPreset: getPreset,
            getPresetsWithBodyType: getPresetsWithBodyType,
            choosePreset: choosePreset,
            applyConfig: applyConfig
        }

        // ---

        function init(locale) {
            loadPresetData(locale).then(function(data) {
                vm.data = data;
            });
        }

        function loadPresetData(locale) {
            return $q(function(resolve, reject) {
                $http.get('/api/presets/' + locale).then(function successCallback(response) {
                    resolve(response.data.payload);
                }, function errorCallback(response) {
                    reject('Preset List not retrieved.');
                });
            });
        }

        function getData() {
            return vm.data;
        }

        function getPreset(presetId) {
            var presets = $.grep(vm.data, function(preset) {
                return preset.id == presetId;
            });

            if (presets.length) return presets[0];
            else return null;
        }

        function getPresetsWithBodyType(type) {
            var presets = [];

            angular.forEach(vm.data, function(preset) {
                angular.forEach(preset.items, function(item) {
                    if (presets.indexOf(preset) != -1) return;
                    
                    if (item.type == 'body' &&
                        item.name.toLowerCase().indexOf(type) != -1) presets.push(preset);
                });
            });

            return presets;
        }
    
        function choosePreset(preset, index) {
            if (CartService.configs().length >= 3 && index == -1) {
                swal(
                    $rootScope.trans.confirm.cabinetCountError.title,
                    $rootScope.trans.confirm.cabinetCountError.text,
                    'warning'
                );
                return;
            }
    
            if (index == -1) {
                index = CartService.addConfig();
            }
            applyConfig(preset, index);
            goToCabinet();
            refreshCabItems(preset, index);
        }

        function applyConfig(preset, index) {
            addConfig(preset, index);
            selectConfig(index);
        }

        function addConfig(preset, index) {
            CartService.addPresetConfig(preset, index);    
            CabService.addConfig(index);
            WashstandService.addConfig(index);
        }

        function selectConfig(index) {
            CartService.selectConfig(index);
            CabService.selectConfig(index);
            WashstandService.selectConfig(index);
        }

        function refreshCabItems(preset, index) {   
            $timeout(function() {
                CabService.autoArrange();
                $rootScope.$broadcast('grid:update');
            });
        }

        function goToCabinet() {
            if ($state.current.name == 'start') $state.go('configurator');
        }
    }

})();