(function () {
    'use strict';

    angular.module('it.services').factory('ScreenshotService', ScreenshotService);

    ScreenshotService.$inject = [
        '$q',
        '$localStorage'
    ];

    function ScreenshotService(
        $q,
        $localStorage
        ) {

        var vm = this;

        vm.images = [];

        return {
            saveCabinetImage: saveCabinetImage,
            saveWashstandImage: saveWashstandImage,
            savePowerPlannings: savePowerPlannings,
            storeImages: storeImages,
            restoreImages: restoreImages,
            getImages: getImages,
            getCabinetImageWithIndex: getCabinetImageWithIndex,
            getWashstandImageWithIndex: getWashstandImageWithIndex,
            reset: reset,
            resetConfig: resetConfig,
        }


        // ---

        function saveCabinetImage(index, state) {
            return saveImage(index, $('.Cabinet'), state);
        }

        function saveWashstandImage(index) {
            return saveImage(index, $('.Washstand'), 'washstand');
        }

        function saveImage(index, element, state) {
            return $q(function(resolve, reject) {
                return renderImage(element).then(function(image) {
                    if (angular.isUndefined(vm.images[index])) {
                        initializeConfig(index);
                    }

                    if (state === 'washstand') vm.images[index].washstand = image;
                    else if (state === 'closed') vm.images[index].closed = image;
                    else vm.images[index].open = image;
                    
                    storeImages();
                    resolve();
                });
            });
        }

        function initializeConfig(index) {
            vm.images[index] = 
            { 
                open: null, 
                closed: null, 
                washstand: null,
                powerPlan: null
            };
        }

        function savePowerPlannings(configs) {
            var counter = configs.length;

            return $q(function (resolve, reject) {
                configs.forEach(function (config, index) {
                    savePowerPlan(index).then(function() {
                        counter--;
                        if (counter == 0) {
                            resolve()
                        }
                    })
                })
            })

        }

        function savePowerPlan(index) {
            var element = $('#PowerPlan-' + index);

            return $q(function (resolve, reject) {
                renderImage(element).then(function (image) {
                    vm.images[index].powerPlan = image;
                    storeImages();
                    resolve(image)
                })
            })
        }

        function storeImages() {
            $localStorage.images = angular.copy(vm.images);
        }

        function restoreImages() {
            vm.images = angular.copy($localStorage.images);
        }

        function getImages() {
            return vm.images;
        }

        function getCabinetImageWithIndex(index) {
            if (angular.isUndefined(vm.images[index]) ||
                angular.isUndefined(vm.images[index].open)) return null;
            
            return vm.images[index].open.dataUrl;
        }

        function getWashstandImageWithIndex(index) {
            if (angular.isUndefined(vm.images[index]) ||
                angular.isUndefined(vm.images[index].washstand)) return null;

            return vm.images[index].washstand.dataUrl;
        }

        function reset() {
            vm.images = [];
            storeImages();
        }

        function resetConfig(index) {
            vm.images[index] = {};
            storeImages();
        }

        function renderImage(element) {
            var width = element.outerWidth(),
                height = element.outerHeight(true);

            // wrong positioning if not scrolled to top!!
            $('body, html').scrollTop(0);

            return $q(function(resolve, reject) {

                html2canvas(element, {
                    onrendered: function(canvas) {
                        var factor = width / height;

                        // resize to 1500px width
                        resizeImage(canvas.toDataURL('image/png'), 1500, 1500 / factor)
                        .then(function(dataUrl) {
                            resolve({
                                width: 1500,
                                height: 1500 / factor,
                                dataUrl: dataUrl,
                            });
                        });

                    },
                    width: width,
                    height: height,
                });
            });
        }

        function resizeImage(dataUrl, width, height) {
            var sourceImage = new Image();

            return $q(function(resolve, reject) {
                sourceImage.onload = function() {
                    var canvas = document.createElement("canvas");
                    canvas.width = width;
                    canvas.height = height;

                    canvas.getContext("2d").drawImage(sourceImage, 0, 0, width, height);
                    resolve(canvas.toDataURL());
                }

                sourceImage.src = dataUrl;
            });
        }

    }

})();