(function () {
    'use strict';

    angular.module('it.directives').directive('washstand', washstand);

    function washstand() {
        return {
            restrict: 'EA',
            templateUrl: 'js/templates/directives/washstand.html',
            controller: WashstandController,
            controllerAs: 'vm',
            bindToController: true,
            scope: {},
        }
    }

    WashstandController.$inject = [
        '$scope',
        '$element',
        'CartService',
        'CabService',
        'ItemService',
        'GridHelper',
        'WashstandService',
        'DragService',
        '$timeout',
        '$state'
    ];

    function WashstandController(
        $scope,
        $element,
        CartService,
        CabService,
        ItemService,
        GridHelper,
        WashstandService,
        DragService,
        $timeout,
        $state) {

        var vm = this;

        vm.rows = null;

        vm.addItem = addItem;

        vm.WashstandService = WashstandService;
        vm.ItemService = ItemService;

        vm.dragEnter = dragEnter;
        vm.dragStart = dragStart;
        vm.dragStop = dragStop;

        vm.draggableIsInGrid = 0;

        vm.getBodySize = CabService.getBodySize;

        // ---
        
        function addItem(data, event, dropCoords) {
            if (DragService.clicked()) return;
            
            var item = data.item,
                dragCoords = data.coords;
            
            if (angular.isUndefined(item)) return;

            if (item.type != 'sink' &&
                item.type != 'faucet') return;

            //Save drop coords for proper placement on config restoration
            if (dropCoords) {
                item.coords = dropCoords;
                console.log("dropCoords:", dropCoords);
            }
            else if (item.coords) {
                dropCoords = item.coords;
                console.log("item.coords:", item.coords);
            }    
            else {
                item.coords = GridHelper.getNextFreeCoordsForItem(vm.rows, item);
                console.log("getNextFreeCoordsForItem:", item.coords);
            }

            // dragCoords not in data object? -> new item
            if (angular.isUndefined(dragCoords)) {
                var itemCopy = angular.copy(item); 
                if (WashstandService.addItem(itemCopy, dropCoords)) {
                    CartService.addItem(itemCopy); 
                }
            } 

            // dragCoords in data object? -> move item
            else {
                WashstandService.moveItem(item, dragCoords, dropCoords);
                CartService.updateItemCoords(item);
            }

            WashstandService.resetHoveredCells();
        }

        function removeItem(data) {
            var item = data.item,
                coords = data.coords;

            WashstandService.removeItem(item, coords);
        }

        function dragEnter(data, event, coords) {
            if (!data) return;

            var item = data.item;
            if (angular.isUndefined(item)) return;

            if (item.type != 'sink' &&
                item.type != 'faucet') return;

            WashstandService.hoverCellsForItem(item, coords);
        }

        function dragStart(data) {
            var item = data.item,
                coords = data.coords;

            if (item.type != 'sink' &&
                item.type != 'faucet') return;

            WashstandService.freeCellsForItem(item, coords);
            GridHelper.onMove($element, WashstandService.rows());            
        }

        function dragStop(data, event) {
            if (DragService.clicked()) return;

            var item = data.item,
                coords = data.coords;

            if (item.type != 'sink' &&
                item.type != 'faucet') return;

            if (!GridHelper.draggableIsInGrid()) removeItem(data);
        }

    }
})();