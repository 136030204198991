(function() {
    'use strict';

    angular.module('it.services').factory('ConfigService', ConfigService);

    ConfigService.$inject = [
        '$localStorage',
        '$rootScope',
        'ConfigObjectService',
        'ScreenshotService',
        'ItemService'
    ];

    function ConfigService(
        $localStorage,
        $rootScope,
        ConfigObjectService,
        ScreenshotService,
        ItemService
    ) {
        var vm = this;

        vm.configs = [];
        vm.selectedConfigIndex = -1;
        vm.trans = $rootScope.trans;

        init();

        return {
            selectConfig: selectConfig,
            addConfig: addConfig,
            addConfigAtPosition: addConfigAtPosition,
            resetConfig: resetConfig,
            removeConfig: removeConfig,
            getConfigs: getConfigs,
            getIndices: getIndices,
            configs: {
                poweredItemsCount: poweredItemsCount,
                powerSocketNeeded: powerSocketNeeded,
                washstandItems: washstandItems,
                accessoryItems: accessoryItems,
                powerItems: powerItems,
                numberOfDistributionBoards: numberOfDistributionBoards,
                itemCount: itemCount
            },
            removeConfigs: removeConfigs,
            selectedConfig: selectedConfig,
            selectedIndex: selectedIndex,
            config: getConfigObject,
            helper: {
                priceForItems: priceForItems,
                groupItems: groupItems,
                groupedOptions: groupedOptions
            },
            getNameSuffix: getNameSuffix
        };

        function init() {
            watchItemDataLoaded();
        }

        function watchItemDataLoaded() {
            $rootScope.$on('itemData:loaded', function(event, data) {
                vm.configs.map(function(config, index) {
                    var items = [];
                    if (config.items && config.items.length) {
                        config.items.map(function(item) {
                            var item = ItemService.getItem(item.id, item.cartIndex);
                            items.push(item);
                        });
                        config.items = items;
                    }
                });
            });
        }

        function selectConfig(index) {
            if (index < 0 || index > vm.configs.length - 1) {
                vm.selectedConfigIndex = -1
            } else {
                vm.selectedConfigIndex = index;
            }
        }

        function addConfig() {
            var config = {
                items: [],
                boards: 0,
                cables: [],
            };

            vm.configs.push(config);
            ScreenshotService.getImages().push({});

            return vm.configs.indexOf(config);
        }

        function addConfigAtPosition(position) {
            if (position == 1) {
                return addConfig();
            }

            var config = {
                    items: [],
                    boards: 0,
                    cables: [],
                },
                images = ScreenshotService.getImages();


            if (position == -1) {
                vm.configs.splice(0, 0, config);
                images.splice(0, 0, {});
            } else if (position == 0) {
                vm.configs.splice(1, 0, config);
                images.splice(1, 0, {});
            }

            return vm.configs.indexOf(config);
        }

        function resetConfig(index) {
            if (index >= vm.configs.length || index < 0) return;
            vm.configs[index].items = [];
        }

        function removeConfig(index) {
            vm.configs.splice(index, 1);

            ScreenshotService.getImages().splice(index, 1);
            ScreenshotService.storeImages();
        }

        function getConfigs() {
            return vm.configs;
        }

        function getIndices() {
            var list = [];
            for (var i = 0; i < vm.configs.length; i++) {
                list.push(i)
            }
            return list;
        }

        function removeConfigs() {
            vm.configs = [];
            $localStorage.savedConfigurations = {};
        }

        // CONFIGS METHODS
        function poweredItemsCount() {
            var count = 0;
            angular.forEach(vm.configs, function(conf, index) {
                var config = getConfigObject(index);
                count += config.get.poweredItems().length
            });
            return count;
        }

        function powerSocketNeeded() {
            var needed = false;
            angular.forEach(vm.configs, function(conf, index) {
                var config = getConfigObject(index);
                needed = needed || config.powerSocketNeeded()
            });
            return needed;
        }

        function washstandItems() {
            var items = [];
            angular.forEach(vm.configs, function(conf, index) {
                var config = getConfigObject(index);
                items = items.concat(config.get.sinks());
                items = items.concat(config.get.faucets());
            });
            return items;
        }

        function accessoryItems() {
            var options = allOptions(),
                interior = filter(options, 'type', 'interior'),
                faucet = filter(options, 'type', 'faucet'),
                items = _.concat([], interior, faucet);
            return items;
        }

        function powerItems() {
            var options = allOptions();
            return filter(options, 'type', 'power');
        }

        function numberOfDistributionBoards() {
            var count = 0;
            angular.forEach(vm.configs, function(config) {
                count += config.boards;
            });
            return count;
        }

        function itemCount() {
            var length = 0;

            angular.forEach(getConfigs(), function(config) {
                var items = config.items;
                length += items.length;

                angular.forEach(items, function(item) {
                    if (angular.isUndefined(item.options)) return;

                    angular.forEach(item.options, function(option) {
                        if (angular.isUndefined(option.amount) ||
                            !option.amount) return;

                        length += option.amount;
                    });
                });
            });

            return length;
        }

        // HELPER METHODS
        function priceForItems(items) {
            return ConfigObjectService.priceForItems(items);
        }

        function groupItems(items) {
            return ConfigObjectService.groupItems(items);
        }

        function groupedOptions(items) {
            return ConfigObjectService.groupOptions(items);
        }

        // CONFIG WRAPPER
        function selectedIndex() {
            return vm.selectedConfigIndex;
        }

        function selectedConfig() {
            if (vm.selectedConfigIndex == -1) return;
            return getConfigObject(vm.selectedConfigIndex);
        }

        function getConfigObject(index) {
            return ConfigObjectService.config(index, vm.configs[index]);
        }

        function getNameSuffix(index) {
            var count = getConfigs().length;
            if (angular.isDefined($rootScope.trans.order)) {
                var suffixes = $rootScope.trans.order.suffix;
                switch(index) {
                    case 0:
                        return (count > 1) ? suffixes.left : null;
                    case 1:
                        return (count > 2) ? suffixes.center : suffixes.right;
                    case 2:
                        return suffixes.right;
                }
            }
        }

        // INTERNAL METHODS
        function allOptions() {
            var configs = [];

            angular.forEach(vm.configs, function(conf, index) {
                var config = getConfigObject(index);
                configs.push(config);
            });
            
            return groupedOptions(configs);
        }

        function filter(items, attr, value) {
            return angular.copy($.grep(items, function(item) {
                return item[attr] == value
            }));
        }
    }
})();