(function() {
    'use strict';
    
    angular.module('it.directives').directive('checkout', checkout);
    
    function checkout() {
        return {
            restrict: 'EA',
            templateUrl: 'js/templates/directives/checkout.html',
            controller: CheckoutController,
            controllerAs: 'vm',
            bindToController: true,
        }
    }
    
    CheckoutController.$inject = [
        '$scope',
        '$rootScope',
        '$state',
        '$http',
        '$sessionStorage',
        'TranslationService',
        'CheckoutService',
        'SwalService',
        'CartService',
        'CanvasService',
        'ScreenshotService',
        'ConfigService'
    ];
    
    function CheckoutController(
         $scope,
         $rootScope,
         $state,
         $http,
         $sessionStorage,
         TranslationService,
         CheckoutService,
         SwalService,
         CartService,
         CanvasService,
         ScreenshotService,
         ConfigService
    ) {
        var vm = this;
        
        vm.trans = $rootScope.trans;

        vm.sendToCustomer = false;
        vm.omitPrices = false;

        vm.customerData = {
            'otherDeliveryAddress': false,
            'commission': '',
            'customer_id': '',
            'company': '',
            'firstname': '',
            'lastname': '',
            'street': '',
            'street_no': '',
            'zip': '',
            'city': '',
            'email': '',
            'phone': '',
            'mobile': '',
            'text': '',
        };
        
        vm.associateData = {
            'depot': '',
            'name' : '',
            'phone' : '',
            'mobile' : '',
        };
    
        init();
        
        function init() {
            if (ConfigService.getConfigs().length == 0) $state.go('start');

            getData();
            watchFormData();
            watchTranslations();
        }
        
        function watchFormData() {
            $scope.$watch(function () {
                return vm.customerData;
            }, function(newValue, oldValue) {
                if (newValue === oldValue) return;
            
                $sessionStorage.customerData = angular.toJson(vm.customerData);
                $sessionStorage.associateData = angular.toJson(vm.associateData);
            }, true);
        }

        function watchTranslations() {
            $rootScope.$watch(function () {
                return $rootScope.trans;
            }, function(newValue, oldValue) {
                if (newValue === oldValue) return;
                vm.trans = $rootScope.trans;                
            }, true);
        }
    
        function getData() {
            if (angular.isDefined($sessionStorage.customerData)) {
                vm.customerData = angular.fromJson($sessionStorage.customerData);
            }
            
            if (angular.isDefined($sessionStorage.associateData)) {
                vm.associateData = angular.fromJson($sessionStorage.associateData);
            }
        }
        
        vm.goBack = goBack;
        vm.sendRequest = sendRequest;
        
        function goBack(event) {
            event.preventDefault();
            $state.go('order');
        }
        
        function sendRequest() {
            $rootScope.showOverlay = true;
            vm.customerData.locale = TranslationService.locale();

            var data = angular.copy(CheckoutService.data());
            
            data.sendToCustomer = vm.sendToCustomer;
            data.omitPrices = vm.omitPrices;

            data.cabinets.map(function(config, index) {
                config.images = ScreenshotService.getImages()[index];
            });
    
            $http.post('/api/orders', {
                data: data,
                customerData: vm.customerData,
                associateData: vm.associateData,
                total: CheckoutService.total(),
            }).then(function (response) {
                $rootScope.showOverlay = false;
                showSuccessAlert();
            }, function (response) {
                $rootScope.showOverlay = false;
                showErrorAlert();
            });
        }
    
        function showSuccessAlert() {
            SwalService.push({
                title: $rootScope.trans.confirm.orderSuccess.title,
                text: $rootScope.trans.confirm.orderSuccess.text,
                type: 'success',
                onConfirm: function() {
                    CartService.removeConfigs();
                    CanvasService.reset();
                    ScreenshotService.reset();
                    CheckoutService.reset();
                    $state.go('start', {}, { reload: true});
                },
            });
        }
    
        function showErrorAlert() {
            SwalService.push({
                title: $rootScope.trans.confirm.orderError.title,
                text: $rootScope.trans.confirm.orderError.text,
                type: 'error'
            });
        }
    }
})();