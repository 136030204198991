(function () {
    'use strict';

    angular.module('it.controllers').controller('LoginController', LoginController);

    LoginController.$inject = [
        '$scope',
        '$rootScope',
        '$state',
        '$localStorage',
        '$q',
        '$http',
        'TranslationService',
        'ItemService',
        'PresetService'
    ];

    function LoginController(
        $scope,
        $rootScope,
        $state,
        $localStorage,
        $q,
        $http,
        TranslationService,
        ItemService,
        PresetService
        ) {

        // ---

        $scope.user = {};

        $scope.login = login;
        $scope.forgotPassword = forgotPassword;
        $scope.error = false;
        $scope.success = false;

        function login() {
            $http.post('/api/auth', $scope.user ).then(function(response) {
                setStatus(response);

                if (isOkOrCreated(response.status)) {
                    $localStorage.token = response.data.payload.token;
                    loadAppData();
                    $state.go('start');
                }

            }, function (response) {
                setStatus(response);
            });
        }

        function forgotPassword() {
            console.log('forgotPassword');
        }


        // ---

        function loadAppData() {
            var locale = TranslationService.locale();
            ItemService.init(locale);
            PresetService.init(locale);
        }

        function setStatus(response) {
            console.log(response);
            $scope.error = !isOkOrCreated(response.status);
            $scope.success = isOkOrCreated(response.status);
        }

        function isOkOrCreated(status) {
            return status === 200 || status === 201;
        }

    }
})();