(function () {
    'use strict';

    angular.module('it.services').factory('DragService', DragService);

    DragService.$inject = [
        '$timeout',
        '$rootScope'
    ];

    function DragService(
        $timeout,
        $rootScope) {

        var vm = this;

        return {
            init: init,
            dragged: dragged,
            clicked: clicked
        }

        // ---

        init();

        function init() {
            reset();

            $rootScope.$on('draggable:move', dragMove);
            $rootScope.$on('draggable:end', dragEnd);
        }

        function reset() {        
            vm.dragged = false;
            vm.clicked = true;
        }

        function dragMove() {      
            vm.dragged = true;
            vm.clicked = false;
        }

        function dragEnd() {
            $timeout(function() {
                reset();
            }, 50);
        }

        function dragged() {
            return vm.dragged;
        }

        function clicked() {
            return vm.clicked;
        }

    }

})();