(function () {
    'use strict';

    angular.module('it.directives').directive('itemPicker', itemPicker);

    function itemPicker() {
        return {
            restrict: 'EA',
            templateUrl: 'js/templates/directives/item-picker.html',
            controller: ItemPickerController,
            controllerAs: 'vm',
            bindToController: true
        }
    }

    ItemPickerController.$inject = [
        '$scope',
        '$rootScope',
        '$http',
        '$state',
        '$q',
        '$element',
        '$timeout',
        'CabService',
        'WashstandService',
        'CartService',
        'ItemService',
        'DragService',
        'CanvasService',
        'GridHelper',
        'PresetService',
        'SwalService'
    ];

    function ItemPickerController(
        $scope,
        $rootScope,
        $http,
        $state,
        $q,
        $element,
        $timeout,
        CabService,
        WashstandService,
        CartService,
        ItemService,
        DragService,
        CanvasService,
        GridHelper,
        PresetService,
        SwalService) {

        var vm = this;

        vm.isHidden = false;
        vm.toggleHidden = toggleHidden;

        vm.categories = [];
        vm.subCategories = [];
        vm.items = [];
        vm.presets = [];

        vm.selectCategory = selectCategory;
        vm.selectSubCategory = selectSubCategory;
        vm.showAddItemDialog = showAddItemDialog;
        vm.showPresetDetails = showPresetDetails;
        vm.addItemFromDetails = addItemFromDetails;
        vm.addItemFromDoubleClick = addItemFromDoubleClick;

        vm.selectedCategory = {};
        vm.selectedSubCategory = {};
        vm.selectedPreset = {};

        vm.maxItems = 99;
        vm.startAtItemIndex = 0;
        vm.prevItemPage = prevItemPage;
        vm.nextItemPage = nextItemPage;
        vm.clear = clear;

        vm.CabService = CabService;
        vm.CartService = CartService;
        vm.CanvasService = CanvasService;

        vm.onDragStart = onDragStart;

        init();

        function init() {
            listenForItemChange();

            var categories = ItemService.getData();
            if (angular.isDefined(categories) && categories.length) {
                vm.categories = categories;
                selectCategory(0);
            }
            watchItems();
        }

        function listenForItemChange() {
            $rootScope.$on('itemData:loaded', function(event, data) {
                // console.log("ITEM DATA LISTENER TRIGGERED");
                // console.log(data);
                vm.categories = data;
                $timeout(function() {
                    selectCategory(0);
                    setMaxItems();
                });
            })
        }

        function watchItems() {
            $scope.$watch(function() {
                return vm.items;
            }, function(newValue, oldValue) {
                vm.startAtItemIndex = 0;
                setMaxItems();
            }, true);
        }

        function selectCategory(index) {
            if (angular.isUndefined(vm.categories)) return;
            vm.selectedCategory = vm.categories[index];
            vm.subCategories = vm.selectedCategory.sub_categories;
            selectSubCategory(0);

            if (isWashstandId(vm.selectedCategory.id)) scrollToWashstand();
            else scrollToCabinet();

            if (isFrontId(vm.selectedCategory.id)) CabService.closeDoor();
            else CabService.openDoor();
        }

        function selectSubCategory(index) {
            vm.selectedSubCategory = vm.subCategories[index];
            vm.startAtItemIndex = 0;

            vm.items = $.grep(vm.selectedSubCategory.items, function(item) {
                return (item.visible &&
                        !item.optional &&
                        CartService.isCompatible(item));
            });
            vm.presets = $.grep(vm.selectedSubCategory.presets, function(preset) {
               return true;
            });
        }

        // todo: no hardcoding
        function isWashstandId(id) {
            return id == 16 || id == 20;
        }

        function isFrontId(id) {
            return id == 5;
        }

        function scrollToCabinet() {
            scrollTo($("#cabinetAnchor"));
        }

        function scrollToWashstand() {
            scrollTo($("#washstandAnchor"));
        }

        function scrollTo(anchor) {
            var container = $("body, html");

            if (angular.isUndefined(anchor)) return;
            if (angular.isUndefined(anchor.offset())) return;

            container.stop().animate(
                { scrollTop: anchor.offset().top - 150 }, '500' );
        }

        function calculateMaxItems() {
            return $q(function(resolve, reject) {
                $timeout(function() {
                    var container = $element.find('.Item-Picker__Items'),
                        items = container.find('.Item'),
                        containerWidth = container.innerWidth(),
                        remainingWidth = container.innerWidth(),
                        itemsWidth = 0,
                        maxItems = 0;

                    $timeout(function() {
                        angular.forEach(items, function (item, index) {
                            var itemWidth = $(item).outerWidth(true);
                            if (remainingWidth >= itemWidth) {
                                remainingWidth -= itemWidth;
                                maxItems++;
                                itemsWidth += itemWidth;
                            }
                        });
                        resolve(maxItems);
                    }, 5);
                }, 50);
            });
        }

        function setMaxItems() {
            vm.maxItems = 999; //Need to get all items beforehand...?
            calculateMaxItems().then(function(maxItems) {
                vm.maxItems = maxItems;
            });
        }

        function prevItemPage() {
            var index = vm.startAtItemIndex - vm.maxItems;
            index = (index < 0) ? 0 : index;
            vm.startAtItemIndex = index;
            calculateMaxItems();
        }

        function nextItemPage() {
            var index = vm.startAtItemIndex + vm.maxItems;
            if (vm.items.length > index || vm.presets.length > index) {
                vm.startAtItemIndex = index;
                calculateMaxItems();
            }

        }

        function clear() {
            CabService.clear();
            CartService.clear();
        }

        function showPresetDetails(preset) {
            if (DragService.dragged()) return;

            vm.selectedPreset = preset;

            $timeout(function() {

                SwalService.push({
                    title: preset.name,
                    text: $element.find('.PresetDetails').parent().html(),
                    html: true,
                    showCancelButton: true,
                    showConfirmButton: true,
                    confirmButtonText: $rootScope.trans.global.add,
                    cancelButtonText: $rootScope.trans.global.close,
                    onConfirm: function() {
                        $timeout(function() {
                            if ($state.current.name == 'start') {
                                PresetService.choosePreset(preset, -1);
                            } else {
                                PresetService.choosePreset(preset, CartService.currentIndex());
                            }
                        })
                    }
                });
            });
        }

        function showAddItemDialog(item) {
            ItemService.showAddItemDialog(item, addItemFromDetails);
        }

        function addItemFromDoubleClick(item) {
            addItemFromDetails(angular.copy(item));
        }

        function addItemFromDetails(item) {
            if (item.type == 'interior') {
                addItemInService(item, CabService);
            } else if (item.type == 'sink' || item.type == 'faucet') {
                addItemInService(item, WashstandService);
            } else if (item.type == 'body') {
                addBodyItem(item)
            } else if (item.type == 'front' ||
                       item.type == 'fitting') {
                CabService.replaceItem(item);
            }
        }

        function addItemInService(item, service) {
            var coords = GridHelper.getNextFreeCoordsForItem(service.rows(), item);
            if (coords) {
                item.cartIndex = CartService.nextCartIndex();
                item.coords = coords;
                service.addItem(item, coords);
                CartService.addItem(item);
            } else {
                SwalService.push({
                    title: $rootScope.trans.confirm.add.title,
                    text: $rootScope.trans.confirm.add.text,
                    type: 'warning',
                });
            }
        }

        function addBodyItem(item) {
            if ($state.current.name == 'start') {
                if (CartService.configs().length < 3) {
                    var index = CartService.addConfig()
                    CartService.resetConfig(index);
                    CartService.selectConfig(index);
                    CartService.addItem(item);

                    CabService.addConfig(index);
                    WashstandService.addConfig(index);
                    $rootScope.saveApply();

                    selectConfig(index);

                } else {
                    SwalService.push({
                        title: $rootScope.trans.confirm.cabinetCountError.title,
                        text: $rootScope.trans.confirm.cabinetCountError.text,
                        type: 'warning',
                    });
                }
            } else {
                CabService.replaceItem(item);
            }
        }

        function selectConfig(index) {
            CartService.selectConfig(index);
            CabService.selectConfig(index);
            WashstandService.selectConfig(index);
            goToCabinet();
        }

        function goToCabinet() {
            if ($state.current.name == 'start') $state.go('configurator');
        }


        function onDragStart(data, event) {
            var item = data.item;
            var preset = data.preset;

            if (angular.isDefined(item)) {
                if (item.type == 'interior') {
                    GridHelper.onMove($('.Cabinet'), CabService.rows());
                }
                else if (item.type == 'sink' || item.type == 'faucet') {
                    GridHelper.onMove($('.Washstand'), WashstandService.rows());
                }

                if (item.type == 'body' ||
                    item.type == 'front' ||
                    item.type == 'fitting') {
                    $rootScope.showBigDroppable = true;
                }
            } else if (angular.isDefined(preset)) {
                $rootScope.showBigDroppable = true;
            }
        }

        function toggleHidden() {
            vm.isHidden = !vm.isHidden;
        }
    }
})();