(function () {
    'use strict';

    angular.module('it.services').factory('CartService', CartService);

    CartService.$inject = [
        'ConfigService',
        'ItemService',
        '$rootScope',
        'SwalService',
        'CanvasBoardService'
    ];

    function CartService(
        ConfigService,
        ItemService,
        $rootScope,
        SwalService,
        CanvasBoardService
    ) {

        var vm = this;
        vm.items = [];
        vm.currentConfigIndex = -1;

        return {
            addConfig: addConfig,
            addConfigAtPosition: addConfigAtPosition,
            removeConfig: removeConfig,
            selectConfig: selectConfig,
            resetConfig: resetConfig,
            configs: getConfigs,
            getNameSuffix: getNameSuffix,
            removeConfigs: removeConfigs,
            currentIndex: getCurrentConfigIndex,
            items: getItems,
            total: getTotal,
            totalLength: getTotalLength,
            nextCartIndex: nextCartIndex,
            addItem: addItem,
            updateItemCoords: updateItemCoords,
            removeItem: removeItem,
            clear: clear,
            getItemReplacedBy: getItemReplacedBy,
            isCompatible: isCompatible,
            getBody: getBody,
            getBodyFromConfigWithIndex: getBodyFromConfigWithIndex,
            getFront: getFront,
            getFitting: getFitting,
            getInterior: getInterior,
            getSinks: getSinks,
            getFaucets: getFaucets,
            getWashstandItems: getWashstandItems,
            getPowerItems: getPowerItems,
            getPoweredItems: getPoweredItems,
            getBatteryPoweredItems: getBatteryPoweredItems,
            getBatteryOnlyPoweredItems: getBatteryOnlyPoweredItems,
            getPowerSupplyPoweredItems: getPowerSupplyPoweredItems,
            getPowerSupplyOnlyPoweredItems: getPowerSupplyOnlyPoweredItems,
            powerSocketNeeded: powerSocketNeeded,
            getAllConfigItemsSorted: getAllConfigItemsSorted,
            mergeItems: mergeItems,
            getIncompatibleItemsForItem: getIncompatibleItemsForItem,
            addPresetConfig: addPresetConfig,
        }

        // ---

        function addConfig() {
            return ConfigService.addConfig();
        }

        function addPresetConfig(preset, index) {
            resetConfig(index);
            selectConfig(index);
            angular.forEach(preset.items, function (item) {
                var itemCopy = angular.copy(item);
                if (itemCopy.type == 'body') delete itemCopy.dependencies.ships_with;
                addItem(item, true);
            });
        }

        function addConfigAtPosition(position) {
            return ConfigService.addConfigAtPosition(position);
        }

        function removeConfig(index) {
            ConfigService.removeConfig(index);
        }

        function resetConfig(index) {
            ConfigService.resetConfig(index);
        }

        function getConfigs() {
            return ConfigService.getConfigs();
        }

        function removeConfigs() {
            ConfigService.removeConfigs();
        }

        function getNameSuffix(index) {
            return ConfigService.getNameSuffix(index);
        }

        // TODO: Should be deleted ??
        function selectConfig(index) {
            ConfigService.selectConfig(index);
            if (index == -1) {
                vm.currentConfigIndex = -1;
                vm.items = [];
                return
            }
            if (index < 0) return;
            var configs = ConfigService.getConfigs();
            if (index >= configs.length) return;

            if(angular.isDefined(configs[index])) {
                vm.items = configs[index].items;
            } else {
                vm.items = [];
            }
            vm.currentConfigIndex = index;
        }


        function getCurrentConfigIndex() {
            return vm.currentConfigIndex;
        }

        function getItems() {
            return vm.items;
        }

        function getTotal() {
            var total = 0.0;
            var configs = ConfigService.getConfigs();

            angular.forEach(configs, function(config) {
                angular.forEach(config.items, function(item) {
                    total += item.price;

                    if (angular.isDefined(item.options)) {
                        angular.forEach(item.options, function(option) {
                            total += option.amount * option.price;
                        });
                    }
                });
            });

            return total;
        }

        function nextCartIndex() {
            return getTotalLength() + 1;
        }

        function addItem(item, preventSwal) {
            preventSwal = preventSwal || false;

            // replaces other item? -> remove replaced item & add to cart
            var replacedItem = getItemReplacedBy(item);
            if (replacedItem) removeItem(replacedItem);

            if (angular.isDefined(item.dependencies.ships_with)) {
                var items = item.dependencies.ships_with.items;
                var itemsString = '';

                angular.forEach(items, function(itemId) {
                    var item = ItemService.getItem(itemId);
                    if (item) {
                        var category = ItemService.getCategoryForItem(itemId);
                        addItem(item);
                        itemsString = itemsString.concat('<strong>' + category.name + ':</strong> ');
                        itemsString = itemsString.concat(item.name + '<br>');
                    }
                });
                if (!preventSwal) {
                    SwalService.push({
                        title: $rootScope.trans.configurator.shipsWith,
                        text: itemsString,
                        html: true,
                        onConfirm: function() {
                            showSwalForHint(item);
                        },
                    });
                }

            } else {
                if (!preventSwal) {
                    showSwalForHint(item);
                }
            }

            if (item.coords) console.log("item.coords", item.coords, vm.items);

            vm.items.push(item);
            updateCartIndices()
        }

        function updateItemCoords(item) {
            if (item.coords) {
                console.log("item.coords", item.coords, vm.items);
                vm.items[vm.items.indexOf(item)].coords = item.coords;
            }
        }

        function showSwalForHint(item) {
            if (item.hint.length) {
                SwalService.push({
                    title: $rootScope.trans.global.hint,
                    text: item.hint,
                    type: 'info'
                });
            }
        }

        function removeItem(item) {
            var itemIndex = vm.items.indexOf(item);
            if (itemIndex == -1) return false;

            var item = vm.items.splice(itemIndex, 1);
            updateCartIndices();
            return item;
        }

        function updateCartIndices() {
            var index = 0;
            angular.forEach(ConfigService.getConfigs(), function(config) {
                angular.forEach(config.items, function(item) {
                    item.cartIndex = ++index;
                });
            });
        }

        function clear() {
            vm.items = [];
        }

        function getItemReplacedBy(newItem) {
            if (newItem.type != 'body' &&
                newItem.type != 'front' &&
                newItem.type != 'fitting') return;

            var items = [];

            items = $.grep(vm.items, function(item) {
                return item.type == newItem.type;
            });

            if (!items.length) return false;

            return items[0];
        }

        function isCompatible(item) {
            var isCompatible = false;
            var compatibleWithIds = item.dependencies.compatible_with;

            if (angular.isUndefined(compatibleWithIds)) isCompatible = true;
            else
            {
                angular.forEach(compatibleWithIds.items, function(compatibleWithId) {
                    angular.forEach(vm.items, function(comparedItem) {
                        if (compatibleWithId == comparedItem.id) {
                            isCompatible = true;
                        }
                    });
                });
            }

            return isCompatible;
        }

        /////////////////////////////////
        // MOVED TO ConfigService
        /////////////////////////////////
        function getBody() {
            return getFirstItemWithType('body');
        }

        function getBodyFromConfigWithIndex(index) {
            if (index < 0) return null;
            selectConfig(index);
            return getBody();
        }

        function getFront() {
            return getFirstItemWithType('front');
        }

        function getFitting() {
            return getFirstItemWithType('fitting');
        }

        function getInterior() {
            return getItemsWithType('interior');
        }

        function getPowerItems() {
            return getItemsWithType('power');
        }

        function getSinks() {
            return getItemsWithType('sink');
        }

        function getFaucets() {
            return getItemsWithType('faucet');
        }

        function getWashstandItems() {
            return getFaucets().concat(getSinks());
        }

        function getItemsWithType(type) {
            return getItemsWithProperty('type', type);
        }

        function getFirstItemWithType(type) {
            var items = getItemsWithType(type);
            return items.length ? items[0] : null;
        }

        function getItemsWithProperty(prop, value) {
            return getFiltered(vm.items, prop, value)
        }

        function getFiltered(items, prop, value) {
            var filteredItems = $.grep(items, function(item, index) {
                return item[prop] == value;
            });

            return filteredItems;
        }

        function powerSocketNeeded() {
            // TODO: better implementation

            var currentIndex = getCurrentConfigIndex();
            var powerSupplyOnlyItems = [];
            angular.forEach(ConfigService.getConfigs(), function(config, index) {
                selectConfig(index);
                var items = getPowerSupplyOnlyPoweredItems();
                powerSupplyOnlyItems = powerSupplyOnlyItems.concat(items);
            });
            selectConfig(currentIndex);
            return powerSupplyOnlyItems.length > 0
        }

        function getPoweredItems() {
            return getItemsWithProperty('powered', 1);
        }

        function getBatteryPoweredItems() {
            return getFiltered(getPoweredItems(), 'battery', 1);
        }

        function getPowerSupplyPoweredItems() {
            return getFiltered(getPoweredItems(), 'powerSupply', 1);
        }

        function getBatteryOnlyPoweredItems() {
            return getFiltered(getFiltered(getPoweredItems(), 'battery', 1), 'powerSupply', 0);
        }

        function getPowerSupplyOnlyPoweredItems() {
            return getFiltered(getFiltered(getPoweredItems(), 'powerSupply', 1), 'battery', 0);
        }

        /////////////////////////////////
        // END OF: MOVED TO ConfigService
        /////////////////////////////////

        function getAllConfigItemsSorted(fullFlatMerge) {
            //TODO: better implementation

            var currentIndex = getCurrentConfigIndex();
            var configs = [];
            angular.forEach(ConfigService.getConfigs(), function(config, index) {
                selectConfig(index);

                var body = getBody(),
                    fitting = getFitting(),
                    front = getFront(),
                    interior = getInterior()
                        .concat(getSinks())
                        .concat(getFaucets())
                        .concat(getPowerItems());

                interior = mergeItems(interior, fullFlatMerge);

                configs[index] = {
                    body: body,
                    fitting: fitting,
                    front: front,
                    interior: interior,
                    price: 3133.70,
                };
            });

            selectConfig(currentIndex);

            return configs;
        }

        function mergeItems(items, fullFlatMerge) {
            var mergedItems = [];

            items = angular.copy(items);

            angular.forEach(items, function(item) {
                var sameItems = mergedItems.filter(function(sameItem) {
                    return sameItem.item_no == item.item_no;
                });

                if (sameItems.length) {
                    var sameItem = sameItems[0];
                    sameItem.amount++;
                    if (sameItem.cartIndices.indexOf(item.cartIndex) == -1) {
                        sameItem.cartIndices.push(item.cartIndex);
                    }

                    if (angular.isDefined(item.options)) {
                        angular.forEach(item.options, function(option) {
                            if (!option.amount) return;

                            var sameOptions = sameItem.options.filter(function(saneOption) {
                                return saneOption.item_no == option.item_no;
                            });

                            if (sameOptions.length) {
                                var sameOption = sameOptions[0];
                                sameOption.amount += option.amount;
                            } else {
                                sameItem.options.push(option);
                            }
                        });
                    }
                } else {
                    item.amount = 1;
                    item.cartIndices = [item.cartIndex];
                    if (angular.isUndefined(item.options)) item.options = [];
                    mergedItems.push(item);
                }
            });

            var mergedOptions = [];
            if (fullFlatMerge) {

                var options = [];
                angular.forEach(mergedItems, function(item) {
                    var opts = angular.copy(item.options);
                    options = options.concat(opts);
                    item.options = [];
                });

                if (options.length > 0) {
                    angular.forEach(options, function(option) {
                        var sameOptions = mergedOptions.filter(function(sameOption) {
                            return sameOption.item_no == option.item_no;
                        });

                        if (sameOptions.length) {
                            var sameOption = sameOptions[0];
                            sameOption.amount += option.amount;
                        } else {
                            if (option.amount == 0)
                                option.amount = 1;
                            mergedOptions.push(option);
                        }
                    });
                }
            }

            return mergedItems.concat(mergedOptions);
        }

        function getIncompatibleItemsForItem(item) {
            var incompatibleItems = [];

            angular.forEach(vm.items, function(itemInCart) {
                if (itemInCart.type == 'front' ||
                    itemInCart.type == 'fitting') return;

                if (!ItemService.isCompatibleWithItem(itemInCart, item)) {
                    incompatibleItems.push(itemInCart);
                }
            });

            return incompatibleItems;
        }

        function getTotalLength() {
            var length = 0;

            angular.forEach(ConfigService.getConfigs(), function(config) {
                var items = config.items;
                length += items.length;

                angular.forEach(items, function(item) {
                    if (angular.isUndefined(item.options)) return;

                    angular.forEach(item.options, function(option) {
                        if (angular.isUndefined(option.amount) ||
                            !option.amount) return;

                        length += option.amount;
                    });
                });
            });

            return length;
        }

    }

})();