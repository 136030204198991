(function () {
    'use strict';

    angular.module('it.services').factory('ItemService', ItemService);

    ItemService.$inject = [
        '$q',
        '$http',
        '$timeout',
        '$state',
        '$rootScope',
        'DragService',
        'SwalService'
    ];

    function ItemService(
        $q,
        $http,
        $timeout,
        $state,
        $rootScope,
        DragService,
        SwalService
        ) {

        var vm = this;

        vm.data = [];

        return {
            init: init,
            getData: getData,
            getItem: getItem,
            getItemWithItemNo: getItemWithItemNo,
            getCategoryForItem: getCategoryForItem,
            getSubCategoryForItem: getSubCategoryForItem,
            getItemsForCategory: getItemsForCategory,
            isCompatibleWithItem: isCompatibleWithItem,
            getCategory: getCategory,
            getBodiesWithType: getBodiesWithType,
            showItemInfoDialog: showItemInfoDialog,
            showAddItemDialog: showAddItemDialog,
            showRemoveItemDialog: showRemoveItemDialog
        }

        // ---

        function init(locale) {
            loadData(locale).then(function(data) {
                vm.data = data;
                // console.log("BROADCAST ITEMDATA LOADED");
                $rootScope.$broadcast('itemData:loaded', data);
            });
        }

        function loadData(locale) {
            return $q(function(resolve, reject) {
                $http.get('/api/items/' + locale).then(function successCallback(response) {
                    resolve(response.data.payload);
                }, function errorCallback(response) {
                    reject('Item List not retrieved.');
                });
            });
        }

        function getData() {
            return vm.data;
        }

        function getItem(itemId, cartIndex) {
            var returnedItem = null;

            angular.forEach(vm.data, function(category) {
                angular.forEach(category.sub_categories, function(subCategory) {
                    angular.forEach(subCategory.items, function(item) {
                        if (item.id == itemId) returnedItem = item;
                    });
                });
            });

            if (cartIndex) returnedItem.cartIndex = cartIndex;
            return returnedItem;
        }

        function getItemWithItemNo(itemNo) {
            var returnedItem = null;

            angular.forEach(vm.data, function(category) {
                angular.forEach(category.sub_categories, function(subCategory) {
                    angular.forEach(subCategory.items, function(item) {
                        if (item.item_no == itemNo) returnedItem = item;
                    });
                });
            });

            return returnedItem;
        }

        function getBodiesWithType(type) {
            var bodies = [];

            angular.forEach(vm.data, function(category) {
                angular.forEach(category.sub_categories, function(subCategory) {
                    angular.forEach(subCategory.items, function(item) {
                        if (item.type == 'body' &&
                            item.name.toLowerCase().indexOf(type) != -1) bodies.push(item);
                    });
                });
            });

            return bodies;
        }

        function getCategoryForItem(itemId) {
            var returnedCategory = null;

            angular.forEach(vm.data, function(category) {
                angular.forEach(category.sub_categories, function(subCategory) {
                    angular.forEach(subCategory.items, function(item) {
                        if (item.id == itemId) returnedCategory = category;
                    });
                });
            });

            return returnedCategory;
        }

        function getSubCategoryForItem(itemId) {
            var returnedSubCategory = null;

            angular.forEach(vm.data, function(category) {
                angular.forEach(category.sub_categories, function(subCategory) {
                    angular.forEach(subCategory.items, function(item) {
                        if (item.id == itemId) returnedSubCategory = subCategory;
                    });
                });
            });

            return returnedSubCategory;
        }

        function getItemsForCategory(categoryId) {
            var returnedItems = [];

            angular.forEach(vm.data, function(category) {
                angular.forEach(category.sub_categories, function(subCategory) {
                    if (subCategory.id == categoryId) returnedItems = subCategory.items;
                });
            });

            return returnedItems;
        }

        function getCategory(categoryId) {
            var returnedCategory = null;

            angular.forEach(vm.data, function(category) {
                angular.forEach(category.sub_categories, function(subCategory) {
                    if (category.id == categoryId) returnedCategory = category;
                    if (subCategory.id == categoryId) returnedCategory = subCategory;
                });
            });

            return returnedCategory;
        }

        function isCompatibleWithItem(item, refItem) {
            var isCompatible = false;
            var compatibleWithIds = item.dependencies.compatible_with;

            if (angular.isUndefined(compatibleWithIds)) isCompatible = true;
            else {
                angular.forEach(compatibleWithIds.items, function(compatibleWithId) {
                    if (compatibleWithId == refItem.id) isCompatible = true;
                });
            }

            return isCompatible;
        };

        function getBodies(argument) {
            // body...
        }

        function showItemInfoDialog(item, isInCart) {
            showItemDetails(item, null, null, isInCart);
        }

        function showRemoveItemDialog(item, callback, coords) {
            showItemDetails(item, callback, coords, true)
        }

        function showAddItemDialog(item, callback) {
            showItemDetails(item, callback);
        }

        function showItemDetails(item, callback, coords, isInCart) {
            if (!item) return;
            
            $rootScope.selectedItem = item;
            if (DragService.dragged()) return;

            var showConfirmButton =
                ($state.current.name == 'start' && item.type == 'body') ||
                ($state.current.name != 'start' && (!isInCart || item.type == 'interior'));

            $timeout(function() {
                var buttonText = $rootScope.trans.global;
                SwalService.push({
                    title: item.name,
                    text: $('.ItemDetails').parent().html(),
                    html: true,
                    showCancelButton: true,
                    showConfirmButton: showConfirmButton,
                    confirmButtonText: coords ? buttonText.remove : buttonText.add,
                    cancelButtonText: buttonText.close,
                    onConfirm: function() {
                        if (coords) callback(item, coords);
                        else callback(angular.copy(item));
                    },
                });
            });
        }
    }

})();