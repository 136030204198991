(function () {
    'use strict';

    angular.module('it.controllers').controller('StartController', StartController);

    StartController.$inject = [
        '$scope',
        '$rootScope',
        '$state',
        '$localStorage',
        '$q',
        'ItemService',
        'CartService',
        'CabService',
        'PresetService',
        'WashstandService',
        '$timeout',
        'TranslationService',
        'SwalService',
        'ScreenshotService',
        'CheckoutService'
    ];

    function StartController(
        $scope,
        $rootScope,
        $state,
        $localStorage,
        $q,
        ItemService,
        CartService,
        CabService,
        PresetService,
        WashstandService,
        $timeout,
        TranslationService,
        SwalService,
        ScreenshotService,
        CheckoutService
    ) {

        $scope.bodies = {
            'slimline': [],
            'euroline': [],
        };

        $scope.presets = {
            'slimline': [],
            'euroline': [],
        };

        $scope.selectConfig = selectConfig;
        $scope.removeConfig = removeConfig;
        $scope.onDropSuccess = onDropSuccess;
        $scope.onPositionDropSuccess = onPositionDropSuccess;
        $scope.onContainerEnter = onContainerEnter;
        $scope.onContainerLeave = onContainerLeave;
        $scope.bigDroppable = true;

        $scope.CartService = CartService;
        $scope.ScreenshotService = ScreenshotService;

        $scope.getCabinetImageWithIndex = getCabinetImageWithIndex;
        $scope.getWashstandImageWithIndex = getWashstandImageWithIndex;

        // ---


        init();

        function init() {
            if (angular.isUndefined($localStorage.token)) return;

            watchPresetData();
            checkForSavedConfigurations();
        }

        function watchPresetData() {
            $scope.$watch(function() {
                return PresetService.data();
            }, function(newValue, oldValue) {
                if (angular.isUndefined(newValue) || !newValue.length) return;

                $scope.presets['slimline'] = PresetService.getPresetsWithBodyType('slimline');
                $scope.presets['euroline'] = PresetService.getPresetsWithBodyType('euroline');
            }, true);
        }

        function onPositionDropSuccess(data, event, position) {
            if (CartService.configs().length >= 3) return;
            
            var index = CartService.addConfigAtPosition(position);
            CabService.prepareForConfigAtPosition(position);
            WashstandService.prepareForConfigAtPosition(position);
            $scope.bigDroppable = true;
            addConfig(data, index);
        }
        
        function onDropSuccess(data, event, index) {
            if (!$scope.bigDroppable && index == -1) return;

            if (index == -1) addConfig(data, index);
            else {
                SwalService.push({
                    title: $rootScope.trans.confirm.overwriteConfig.title,
                    text: $rootScope.trans.confirm.overwriteConfig.text,
                    html: true,
                    showCancelButton: true,
                    onConfirm: function() {
                        addConfig(data, index);
                    },
                });
            }
            
        }

        function addConfig(data, index) {
            $scope.bigDroppable = true;
            if (angular.isDefined(data.item)) {
                var item = data.item;

                if (item.type != 'body') return;
                else chooseBody(item, index);
            } else if (angular.isDefined(data.preset)) {
                var preset = data.preset;
                PresetService.choosePreset(preset, index);
            }

            if (index == -1) index = 0;
            selectConfig(index);        
        }

        function selectConfig(index) {
            CartService.selectConfig(index);
            CabService.selectConfig(index);
            WashstandService.selectConfig(index);
            goToCabinet();
        }

        function goToCabinet() {          
            if ($state.current.name == 'start') $state.go('configurator');
        }
        
        function onContainerLeave() {
            $scope.bigDroppable = true;
        }
        
        function onContainerEnter() {
            $scope.bigDroppable = false;
        }

        function chooseBody(body, index) {
            if (index == -1) {
                index = CartService.addConfig();
            }

            CartService.resetConfig(index);
            CartService.selectConfig(index);
            CartService.addItem(body);

            CabService.addConfig(index);
            WashstandService.addConfig(index);
        }

        function checkForSavedConfigurations() {
            if (CartService.configs().length) return;

            var unregister = $scope.$watch(function() {
                return $rootScope.trans.confirm;
            }, function(newValue) {
                if (angular.isUndefined(newValue)) return;

                if (angular.isDefined($localStorage.savedConfigurations)) {
                    var savedConfigurations = $localStorage.savedConfigurations;

                    if (angular.isObject(savedConfigurations) &&
                        angular.isDefined(savedConfigurations.cart) &&
                        savedConfigurations.cart.length) {

                        SwalService.push({
                            title: $rootScope.trans.confirm.restoreConfiguration.title,
                            text: $rootScope.trans.confirm.restoreConfiguration.text,
                            showCancelButton: true,
                            onConfirm: function() {
                                restoreConfigurations();
                            },
                            onCancel: function() {
                                ScreenshotService.reset();
                            },
                        });

                    }
                }
                else $localStorage.savedConfigurations = {};

                unregister();
            });
            
        }

        function restoreConfigurations() {
            $timeout(function() {
                var configs = $localStorage.savedConfigurations;
                // console.log("getConfig: ", configs, "lastItem.coords:", configs.cart[0].items[configs.cart[0].items.length - 1].coords);
                angular.forEach(configs.cart, function(cart) {
                    var index = CartService.addConfig();
                    PresetService.applyConfig(cart, index);
                    CabService.autoArrange();
                    WashstandService.placeItems();
                    $rootScope.$broadcast('grid:update');
                });

                ScreenshotService.restoreImages();

                /** if cabinet was showing on last exit, 
                go there again for proper screen shooting. */
                $timeout(function() {
                    if (configs.selectedConfigIndex !== null) {
                        selectConfig(configs.selectedConfigIndex);
                    }
                });
            });
        }

        function getCabinetImageWithIndex(index) {
            return ScreenshotService.getCabinetImageWithIndex(index);

        }

        function getWashstandImageWithIndex(index) {
            return ScreenshotService.getWashstandImageWithIndex(index);
        }

        function removeConfig(index) {
            SwalService.push({
                title: $rootScope.trans.confirm.removeConfig.title,
                text: $rootScope.trans.confirm.removeConfig.text,
                showCancelButton: true,
                onConfirm: function() {
                    CabService.removeConfig(index);
                    CartService.removeConfig(index);
                    CheckoutService.update();
                },
            });
        }

    }
})();