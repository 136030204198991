(function () {
    'use strict';
    
    angular.module('it.directives').directive('presetDetails', itemDetails);
    
    function itemDetails() {
        return {
            restrict: 'E',
            templateUrl: 'js/templates/directives/preset-details.html',
            scope: {
                preset: '='
            }
        }
    }
    
})();