(function () {
    'use strict';

    angular.module('it.services').factory('NavService', NavService);

    NavService.$inject = [
        '$rootScope',
        '$timeout',
        '$localStorage',
        '$state',
        '$q',
        'CartService',
        'CabService',
        'ScreenshotService'
    ];

    function NavService(
        $rootScope,
        $timeout,
        $localStorage,
        $state,
        $q,
        CartService,
        CabService,
        ScreenshotService
        ) {

        var vm = this;

        return {
            goToStart: goToStart,
            goToOrder: goToOrder
        }

        // ---

        function goToStart() {
            goTo('start');
        }

        function goToOrder() {
            goTo('order');
        }

        function goTo(stateName) {
            if (angular.isUndefined($localStorage.token)) $state.go('login');
            else if ($state.current.name != 'configurator') $state.go(stateName);
            else {
                takeScreenshots().then(function() {
                    if (stateName === 'start') CartService.selectConfig(-1);
                    $rootScope.showOverlay = false;
                    $state.go(stateName);
                });
            }
        }

        function removeSelectedConfig() {
            $localStorage.savedConfigurations.selectedConfigIndex = null;
        }      

        function takeScreenshots() {
            CabService.hideInstallations();
            removeSelectedConfig();

            return $q(function(resolve, reject) {
                $timeout(function() {
                    $rootScope.showOverlay = true;
                    screenshots(resolve);
                });
            });
        }

        function getIndex() {
            return CartService.currentIndex();
        }

        function screenshots(resolve) {
            CabService.openDoor();
            $timeout(function () {
                takeOpenScreenshot().then(function() {
                    CabService.closeDoor();
                    takeWashstandScreenshot().then(function () {
                        $timeout(function() {
                            takeClosedScreenshot()
                                .then(function() {
                                    resolve();
                                })
                        })
                    })
                });
            })
        }

        function takeOpenScreenshot() {
            return ScreenshotService.saveCabinetImage(getIndex(), 'open');
        }

        function takeClosedScreenshot() {
            return ScreenshotService.saveCabinetImage(getIndex(), 'closed');
        }

        function takeWashstandScreenshot() {
            return ScreenshotService.saveWashstandImage(getIndex());
        }
    }

})();