(function () {
    'use strict';
    
    angular.module('it.services').factory('CanvasBoardService', CanvasBoardService);
    
    CanvasBoardService.$inject = [
        'DrawService',
        'CanvasDimensionsService'
    ];
    
    function CanvasBoardService(
        DrawService,
        CanvasDimensionsService
    ) {
        
        var vm = this;
        
        vm.innerDimensions = {};
        vm.boards = [];
        vm.possibleBoardCoords = [];
        vm.items = [];
    
        vm.defaultBoard = {
            width: 75,
            height: 30,
            coords: {},
            pins: []
        };
        
        return {
            init: init,
            drawBoards: drawBoards,
            numberOfBoards: numberOfBoards,
            getBoards: getBoards,
            getNextFreePin: getNextFreePin,
        };
    
        function init(dimensions, items) {
            vm.innerDimensions = dimensions;
            vm.items = items;
            vm.defaultBoard = {
                width: 75,
                height: 30,
                coords: {},
                pins: []
            };
            initBoards()
        }
    
        function drawBoards() {
            console.log("Boards to draw:", vm.boards);
            
            angular.forEach(vm.boards, function(board) {
                DrawService.drawImage(
                    'board',
                    {
                        x: board.coords.x,
                        y: board.coords.y,
                        width: board.width,
                        height: board.height
                    }
                );
            });
        }
        
        function numberOfBoards() {
            return vm.boards.length;
        }
        
        function getBoards() {
            return vm.boards;
        }
        
        function getNextFreePin(coords) {
            var distance = Number.MAX_SAFE_INTEGER;
            var nearestPin = null;
            
            angular.forEach(vm.boards, function(board) {
                var delta = Math.abs(coords.x - (board.coords.x + board.width/2));
                if (delta < distance) {
                    var freepins = $.grep(board.pins, function(pin) {
                        return pin.used == false;
                    });
                    if (freepins.length) {
                        distance = delta;
                        nearestPin = freepins[0];
                    }
                }
            })
            return nearestPin
        }
        
        // INTERNAL
        
        function initBoards() {
            vm.boards = [];
            if (vm.items.length == 0) return;
            vm.possibleBoardCoords = boardCoordinates();
            
            var neededCoordinates = [];
            
            angular.forEach(vm.items, function (item) {
                var canvasCoords = CanvasDimensionsService.canvasCoordinateForItem(item);
                if (canvasCoords) {
                    var boardCoords = nearestBoardCoords(canvasCoords);
                    if (neededCoordinates.indexOf(boardCoords) == -1) {
                        neededCoordinates.push(boardCoords);
                    }
                }
            });
            
            neededCoordinates.sort(function(c1, c2) {
               return c1.x - c2.x
            });
            
            buildBoards(neededCoordinates);
        }
        
        function nearestBoardCoords(coords) {
            var distance = Number.MAX_SAFE_INTEGER;
            var indexOfNearest = -1;
            
            angular.forEach(vm.possibleBoardCoords, function(boardCoords, index) {
                var delta = Math.abs((boardCoords.x + CanvasDimensionsService.canvasWidth(vm.defaultBoard.width)/2) - coords.x);
                if (delta < distance) {
                    distance = delta;
                    indexOfNearest = index;
                }
            });
            return vm.possibleBoardCoords[indexOfNearest];
        }
        
        function buildBoards(neededCoords) {
            var initialOffset = CanvasDimensionsService.canvasWidth(10),
                pinOffset = CanvasDimensionsService.canvasWidth(14);
            
            vm.boards = [];
            
            angular.forEach(neededCoords, function (coords) {
                var board = angular.copy(vm.defaultBoard);
                board.width = CanvasDimensionsService.canvasWidth(board.width);
                board.height = CanvasDimensionsService.canvasHeight(board.height);
                board.coords = coords;
                for (var i = 0; i < 5; i++) {
                    board.pins.push({
                        x: board.coords.x + initialOffset + (i * pinOffset),
                        y: board.coords.y + board.height,
                        used: false
                    })
                }
                vm.boards.push(board);
            });
        }
        
        function boardCoordinates() {
            var xCoords = boardCoordinatesX();
            var coords = [];
            angular.forEach(xCoords, function(coordX) {
                coords.push({
                    x: coordX,
                    y: vm.innerDimensions.height - CanvasDimensionsService.canvasHeight(30) - (CanvasDimensionsService.canvasHeight(vm.defaultBoard.height) / 2)
                })
            });
            return coords;
        }
        
        function boardCoordinatesX() {
            if (CanvasDimensionsService.bodyDimensions().outerWidth == 1500) {
                return [
                    originFromLeft(86),
                    originFromLeft(447),
                    originCenterBoard(),
                    originFromRight(447),
                    originFromRight(86)
                ]
            } else {
                return [
                    originFromLeft(86),
                    originCenterBoard(),
                    originFromRight(86),
                ]
            }
        }
        
        function originFromRight(mmVal) {
            return vm.innerDimensions.width - CanvasDimensionsService.canvasWidth(mmVal) - CanvasDimensionsService.canvasWidth(vm.defaultBoard.width);
        }
    
        function originFromLeft(mmVal) {
            return CanvasDimensionsService.canvasWidth(mmVal);
        }
        
        function originCenterBoard() {
            return (vm.innerDimensions.width) / 2 - (CanvasDimensionsService.canvasWidth(vm.defaultBoard.width) / 2);
        }
    }
})();