(function () {
    'use strict';

    angular.module('it.directives').directive('order', order);

    function order() {
        return {
            restrict: 'EA',
            templateUrl: 'js/templates/directives/order/order.html',
            controller: OrderController,
            controllerAs: 'vm',
            bindToController: true,
            scope: {
                showForm: '='
            },
        }
    }

    OrderController.$inject = [
        '$rootScope',
        '$state',
        '$timeout',
        'ConfigService',
        'CheckoutService',
        'ScreenshotService'
    ];

    function OrderController(
        $rootScope,
        $state,
        $timeout,
        ConfigService,
        CheckoutService,
        ScreenshotService
    ) {

        var vm = this;

        vm.configs = [];
        vm.trans = $rootScope.trans;
        
        vm.powerItems = [];
        vm.powerItemsOpen = false;
        vm.accessoryItems = [];
        vm.washstandItems = [];
        
        
        vm.total = total;
        vm.vat = vat;
        vm.shipping = shipping;

        vm.goBack = goBack;
        vm.proceed = proceed;

        // ---

        init();

        function init() {
            if (!ConfigService.getConfigs().length) $state.go('start');

            loadConfigs();
            watchTranslations();
        }

        function loadConfigs() {
            vm.configs = ConfigService.getConfigs();
        }

        function watchTranslations() {
            $rootScope.$watch(function () {
                return $rootScope.trans;
            }, function(newValue, oldValue) {
                if (newValue === oldValue) return;
                vm.trans = $rootScope.trans;                
            }, true);
        }
        
        function total() {
            return CheckoutService.total()
        }
        
        function vat() {
            return CheckoutService.vat()
        }
        
        function shipping() {
            return CheckoutService.shipping()
        }

        function goBack(event) {
            event.preventDefault();
            $state.go('start');
        }
        
        function proceed(event) {
            event.preventDefault();

            $rootScope.showOverlay = true;
            vm.powerItemsOpen = true;
            $rootScope.$broadcast('grid:updateItems');

            $timeout(function () {
                ScreenshotService
                    .savePowerPlannings(vm.configs)
                    .then(function () {
                        $rootScope.showOverlay = false;
                        $state.go('checkout');
                    })
            }, 500);
        }

    }
})();