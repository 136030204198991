(function() {
    'use strict';
    
    angular.module('it.directives').directive('washstandOverview', washstandOverview);
    
    function washstandOverview() {
        return {
            restrict: 'E',
            templateUrl: 'js/templates/directives/order/washstand-overview.html',
            controller: WashstandOverviewController,
            controllerAs: 'vm',
            bindToController: true,
            scope: {
                open: '@',
                trans: '=',
            }
        }
    }
    
    WashstandOverviewController.$inject = [
        '$rootScope',
        'CheckoutService'
    ];
    
    function WashstandOverviewController(
        $rootScope,
        CheckoutService
    ) {
        var vm = this;
        
        vm.opened = false;
        
        vm.items = function() {
            return CheckoutService.data().washstand.items
        };
        vm.subtotal = function() {
            return CheckoutService.data().washstand.price;
        };
        
        init();
        
        function init() {
            if(vm.open == 'open') {
                vm.opened = true
            }
        }
    }
})();