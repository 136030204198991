(function () {
    'use strict';

    angular.module('it.services').factory('TranslationService', TranslationService);

    TranslationService.$inject = [
        '$http',
        '$q'
    ];

    function TranslationService(
        $http,
        $q) {

        var vm = this;

        vm.data = {};
        vm.locale = 'de'; // set default

        return {
            init: init,
            locale: getLocale,
            setLocale: setLocale,
            getTranslations: getTranslations
        }

        // ---

        init();

        function init() {
            return $q(function(resolve, reject) {
                loadData().then(function(data) {
                    vm.data = data;
                    resolve()
                });
            });
        }

        function loadData() {
            return $q(function(resolve, reject) {
                $http.get('/api/translations').then(function successCallback(response) {
                    resolve(response.data.payload);
                }, function errorCallback(response) {
                    reject('Translations not retrieved.');
                });
            });
        }

        function getLocale() {
            return vm.locale;
        }

        function setLocale(locale) {
            vm.locale = locale;
        }

        function getTranslations() {
            return vm.data[vm.locale];
        }
    }

})();