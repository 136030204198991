(function () {
  'use strict';
  
  angular.module('it.filters').filter('htmlToPlainText', htmlToPlainText);
  
  function htmlToPlainText() {
    return function(html) {
        if (html == null) return "";
        var tag = document.createElement('div');
        tag.innerHTML = html;
    
        return tag.innerText;
    }
  }
})();