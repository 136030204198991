(function() {
    'use strict';
    
    angular.module('it.directives').directive('powerItemsOverview', powerItemsOverview);
    
    function powerItemsOverview() {
        return {
            restrict: 'E',
            templateUrl: 'js/templates/directives/order/power-items-overview.html',
            controller: PowerItemsOverviewController,
            controllerAs: 'vm',
            bindToController: true,
            scope: {
                readonly: '@',
                opened: '=?',
                trans: '=',
            }
        }    
    }
    
    PowerItemsOverviewController.$inject = [
        '$rootScope',
        '$scope',
        '$timeout',
        'ConfigService',
        'CanvasService',
        'CheckoutService',
        'ScreenshotService'
    ];
    
    function PowerItemsOverviewController(
        $rootScope,
        $scope,
        $timeout,
        ConfigService,
        CanvasService,
        CheckoutService,
        ScreenshotService
    ) {
        var vm = this;
        vm.items = function() {
            return CheckoutService.data().power.items
        };
        vm.configIndices = ConfigService.getIndices;

        vm.toggleOpen = toggleOpen;
        vm.powerSource = 'battery';
        vm.setPowerSource = setPowerSource;
        vm.powerSocketNeeded = false;
    
        vm.subtotal = subtotal;
        vm.increase = increase;
        vm.increaseable = increaseable;
        vm.decrease = decrease;
        vm.decreaseable = decreaseable;

        vm.getBodyType = getBodyType;
        vm.getBodySize = getBodySize;

        vm.cabinetImage = ScreenshotService.getCabinetImageWithIndex;
        
        init();
        
        function init() {
            if(vm.readonly) {
                var powerData = CheckoutService.data().power;
                vm.powerSource = powerData.source;
                vm.opened = true;
            } else {
                update();
            }
        }
        
        function update() {
            getSocketPossibilities();
            watchItems();
        }

        function toggleOpen() {
            vm.opened = !vm.opened;
            $timeout(function () {
                $scope.$broadcast('grid:updateItems');
            })
        }
        
        function getSocketPossibilities() {
            vm.powerSocketNeeded = ConfigService.configs.powerSocketNeeded();
            
            var src = CanvasService.getPowerSource();
            var sock = CanvasService.getSocketType();
            
            if (src == 'battery' && !vm.powerSocketNeeded) {
                vm.powerSource = 'battery'
            } else if (src == 'power') {
                vm.powerSource = sock;
            }
            
            $timeout(function() {
                CheckoutService.updatePowerItemsForSource(vm.powerSource);
            })
        }
    
        function watchItems() {
            $scope.$watch(function() {
                return vm.items();
            }, function() {
                CheckoutService.data().power.price = subtotal();
                $scope.$broadcast('grid:updateItems');
            }, true);
        }
        
        function setPowerSource(source) {
            if (source == 'battery' && vm.powerSocketNeeded) return;
            vm.powerSource = source;
            CheckoutService.updatePowerItems(source);
            
            var src = CanvasService.getPowerSource();
            var sock = CanvasService.getSocketType();
            
            if (source == 'battery') {
                if (src == 'battery') return;
                CanvasService.togglePowerSource();
            } else {
                if (src == 'battery') CanvasService.togglePowerSource();
                
                if(sock == source) return;
                CanvasService.toggleSocketType();
            }
        }
    
        function increase(item) {
            if (!increaseable(item)) return;
            item.amount += 1;
        }
    
        function increaseable(item) {
            if ( !CheckoutService.activeForSource(item) ) return false;
            return true
        }
    
        function decrease(item) {
            if (!decreaseable(item)) return;
            item.amount -= 1;
        }
    
        function decreaseable(item) {
            if ( !CheckoutService.activeForSource(item) ) return false;
            
            if (item.amount > item.minCount) {
                return true
            }
        }
    
        function subtotal() {
            return ConfigService.helper.priceForItems(vm.items());
        }

        function getBodyType(index) {
            return ConfigService.config(index).get.bodyType();
        }

        function getBodySize(index) {
            return ConfigService.config(index).get.bodySize();
        }
    }
})();