(function () {
    'use strict';

    angular.module('it.controllers').controller('RootController', RootController);

    RootController.$inject = [
        '$scope',
        '$rootScope',
        '$state',
        'ItemService',
        'PresetService',
        'DragService',
        'CartService',
        'CabService',
        'WashstandService',
        'TranslationService',
        'SwalService',
        'CanvasService',
        'ConfigService',
        'ScreenshotService',
        'NavService',
        '$localStorage',
        '$timeout',
        'CheckoutService',
        '$window',
    ];

    function RootController(
        $scope,
        $rootScope,
        $state,
        ItemService,
        PresetService,
        DragService,
        CartService,
        CabService,
        WashstandService,
        TranslationService,
        SwalService,
        CanvasService,
        ConfigService,
        ScreenshotService,
        NavService,
        $localStorage,
        $timeout,
        CheckoutService,
        $window
    ) {

        $scope.showHints = false;
        $rootScope.showOverlay = false;
        $rootScope.trans = {};

        $scope.CartService = CartService;
        $scope.ConfigService = ConfigService;
        $scope.NavService = NavService;

        $scope.showContent = showContent;
        $scope.resetAll = resetAll;
        $scope.reset = reset;
        $scope.toggleLocale = toggleLocale;
        $scope.signOut = signOut;
        $scope.isSignedIn = isSignedIn;
        $scope.toggleHints = toggleHints;

        $rootScope.$state = $state;

        $rootScope.saveApply = function(fn) {
            var phase = this.$root.$$phase;
            if(phase == '$apply' || phase == '$digest') {
                if(fn && (typeof(fn) === 'function')) {
                    fn();
                }
            } else {
                this.$apply(fn);
            }
        };

        // ---

        init();

        function init() {
            watchLoginState();
            onResize();

            DragService.init();
            TranslationService.init().then(function() {
                watchLocale();
            });
        }

        function watchLoginState() {
            $rootScope.$on('$stateChangeSuccess', function (event, toState, fromState, fromParams, error) {
                if (toState.name == 'login') return;
                if (toState.name == 'signup') return;
                if (toState.name == 'confirm') return;
                if (isSignedIn()) return;

                $timeout(function () {
                    $state.go('login');
                })
            })
        }

        function watchLocale() {
            $scope.$watch(function () {
                return TranslationService.locale();
            }, function(newValue, oldValue) {
                $rootScope.trans = TranslationService.getTranslations();
                ItemService.init(newValue);
                PresetService.init(newValue);

                swal.setDefaults({
                    confirmButtonText: $rootScope.trans.global.confirm,
                    cancelButtonText: $rootScope.trans.global.cancel,
                });

                showAlertForSmallDevices();
                showAlertForBrowsers();
            });
        }

        function toggleLocale() {
            var locale = TranslationService.locale() == 'de' ? 'en' : 'de';
            TranslationService.setLocale(locale);
        }

        function signOut() {
            console.log('signOut');
            delete $localStorage.token;
            $state.go('login');
        }

        function isSignedIn() {
            return angular.isDefined($localStorage.token);
        }

        function showStartDialog() {
            if ($scope.startDialogShown) return;
            $scope.startDialogShown = true;
        }

        function showHintDialogOnLocaleToggle() {
            if ($scope.toggleLocaleDialogShown) return;
            $scope.toggleLocaleDialogShown = true;
        }

       function resetAll() {
            askForResetConfigurations()
        }


        function reset() {
            var stateName = $state.current.name;

            if (stateName == 'start') {
                askForResetConfigurations()
            } else if (stateName == 'configurator') {
                askForResetCurrentConfiguration()
            }
        }

        function askForResetCurrentConfiguration() {
            SwalService.push({
                title: $rootScope.trans.confirm.reset.title,
                text: $rootScope.trans.confirm.reset.text,
                showCancelButton: true,
                onConfirm: resetCurrentConfiguration,
            });
        }

        function resetCurrentConfiguration() {
            var index = CartService.currentIndex(),
                body = CartService.getBodyFromConfigWithIndex(index);

            CartService.resetConfig(index);
            CartService.selectConfig(index);
            CartService.addItem(body);

            CabService.clearGrid();
            CabService.addConfig(index);
            WashstandService.clearGrid();
            WashstandService.addConfig(index);

            CanvasService.reset();
        }

        function askForResetConfigurations() {
            SwalService.push({
                title: $rootScope.trans.confirm.resetAll.title,
                text: $rootScope.trans.confirm.resetAll.text,
                showCancelButton: true,
                onConfirm: resetConfigurations,
            });
        }

        function resetConfigurations() {
            CartService.removeConfigs();
            CanvasService.reset();
            ScreenshotService.reset();
            CheckoutService.reset();

            if ($state.current.name != 'start') {
                $state.go('start', {}, { reload: true});
            }
        }

        function showContent(key) {
            SwalService.push({
                title: $rootScope.trans.contents[key].title,
                text: $rootScope.trans.contents[key].body,
                html: true,
            });
        }

        function toggleHints() {
            $("body, html").scrollTop(0);
            $scope.showHints = !$scope.showHints;
        }

        function showAlertForSmallDevices() {
            if ($(window).width() < 768) {
                SwalService.push({
                    title: $rootScope.trans.confirm.smallDevices.title,
                    text: $rootScope.trans.confirm.smallDevices.text,
                });
            }
        }

        function showAlertForBrowsers() {
            if (!bowser.chrome && 
                !bowser.msedge &&
                !bowser.safari) {
                showContent('performance');
            }
        }

        function onResize() {
            $window.addEventListener("resize", function() {
                $rootScope.$emit('grid:update');
            });
        }
    }
})();