(function () {
    'use strict';

    angular.module('it-config').config(config);

    function config(
        $stateProvider,
        $urlRouterProvider) {

        $urlRouterProvider.otherwise('/login');

        $stateProvider
            .state('start', {
                url: '/start',
                views: {
                    'content': {
                        controller: 'StartController',
                        templateUrl: 'js/templates/start.html',
                    },
                    'itemPicker': {
                        template: '<item-picker></item-picker>',
                    },
                    'cart': {
                        template: '<cart></cart>',
                    },
                },
            })
            .state('login', {
                url: '/login',
                views: {
                    'content': {
                        controller: 'LoginController',
                        templateUrl: 'js/templates/login.html',
                    }
                },
            })
            .state('signup', {
                url: '/signup',
                views: {
                    'content': {
                        controller: 'SignupController',
                        templateUrl: 'js/templates/signup.html',
                    }
                },
            })
            .state('configurator', {
                url: '/configurator',
                views: {
                    'content': {
                        template: '<cabinet></cabinet><washstand></washstand>'
                    },
                    'toolbar': {
                        template: '<toolbar></toolbar>',
                    },
                    'itemPicker': {
                        template: '<item-picker></item-picker>',
                    },
                    'cart': {
                        template: '<cart></cart>',
                    },
                },
                params: {
                    preset: null,
                },
                onEnter: function($localStorage, ConfigService) {
                    if (ConfigService.selectedIndex() >= 0) {
                        $localStorage.savedConfigurations.selectedConfigIndex = ConfigService.selectedIndex();
                    }
                }
            })
            .state('print', {
                url: '/print',
                views: {
                    'content': {
                        template: '<print></print>',
                    },
                },
            })
            .state('order', {
                url: '/order',
                views: {
                    'content': {
                        template: '<order></order>',
                    },
                },
                params: {
                    addPowerItems: false,
                },
            })
            .state('checkout', {
                views: {
                    'content': {
                        template: '<checkout></checkout>'
                    }
                }
            })
            .state('confirm', {
                url: '/confirm/{emailToken}',
                views: {
                    'content': {
                        controller: 'ConfirmController',
                        templateUrl: 'js/templates/confirm.html',
                    }
                },
            });
    }
})();