(function () {
    'use strict';
    
    angular.module('it.services').factory('CanvasSocketService', CanvasSocketService);
    
    CanvasSocketService.$inject = [
        'DrawService',
        'CanvasBoardService',
        'CanvasCordService',
        'CanvasDimensionsService'
    ];
    
    function CanvasSocketService(
        DrawService,
        CanvasBoardService,
        CanvasCordService,
        CanvasDimensionsService
    ) {
        
        var vm = this;
        
        vm.dimensions = {};
        vm.sockets = [];
        
        return {
            init: init,
            drawPowerSocket: drawPowerSocket,
            drawFittingSocket: drawFittingSocket
        };
        
        function init(dimensions) {
            vm.dimensions = dimensions;
            initSockets();
        }
    
        function drawPowerSocket(socketType) {
            var socketToDraw = getSocketsWithType(socketType)[0];
            drawSocketImage(socketToDraw);
            drawConnectionForSocket(socketToDraw)
        }
        
        function drawFittingSocket(fitting) {
            var socketToDraw = getSocketsWithType('fittingPowerSocket')[0];
            drawSocketImage(socketToDraw);
            drawFittingConnection(fitting, socketToDraw);
        }
        
        // INTERNAL
    
        function initSockets() {
            vm.sockets = [];
        
            vm.sockets.push({
                width: CanvasDimensionsService.canvasWidth(75),
                height: CanvasDimensionsService.canvasWidth(87),
                offset: CanvasDimensionsService.canvasWidth(15),
                x: (vm.dimensions.width) / 3 * 2 - CanvasDimensionsService.canvasWidth(37.5),
                y: (vm.dimensions.height) / 3,
                type: 'inWallSocket',
            });
        
            vm.sockets.push({
                width: CanvasDimensionsService.canvasWidth(60),
                height: CanvasDimensionsService.canvasWidth(169.5),
                offset: CanvasDimensionsService.canvasWidth(30),
                x: (vm.dimensions.width) / 3 * 2 - CanvasDimensionsService.canvasWidth(30),
                y: CanvasDimensionsService.canvasWidth(-225),
                type: 'powerSocket',
            });
        
            vm.sockets.push({
                width: CanvasDimensionsService.canvasWidth(60),
                height: CanvasDimensionsService.canvasWidth(169.5),
                offset: CanvasDimensionsService.canvasWidth(30),
                x: (vm.dimensions.width) / 3 - CanvasDimensionsService.canvasWidth(30),
                y: CanvasDimensionsService.canvasWidth(-225),
                type: 'fittingPowerSocket',
            });
        }
        
        function getSocketsWithType(type) {
            return $.grep(vm.sockets, function(socket) {
                return socket.type == type;
            });
        }
        
        function drawSocketImage(socket) {
            DrawService.drawImage(
                socket.type,
                {
                    x: socket.x,
                    y: socket.y,
                    width: socket.width,
                    height: socket.height
                }
            );
        }
        
        function drawFittingConnection(fitting, socket) {
            var origin = {
                x: socket.x + socket.width / 2,
                y: socket.y + socket.height,
            };
            
            var offset = {
                left: fitting.dimensions.units.powerOffsetLeft,
                top: fitting.dimensions.units.powerOffsetTop
            };
            var destination = {
                x: CanvasDimensionsService.canvasWidth(offset.left),
                y: CanvasDimensionsService.canvasWidth(offset.top)
            };
            var coord = [origin];
            coord.push({
                x: origin.x,
                y: origin.y + CanvasDimensionsService.canvasHeight(10)
            });
            coord.push({
                x: destination.x,
                y: origin.y + CanvasDimensionsService.canvasHeight(10)
            });
            coord.push(destination);
            CanvasCordService.drawSocketCord(coord);
    
            var length = origin.x -  destination.x;
            var start = {
                x: origin.x - (length/3),
                y: origin.y + CanvasDimensionsService.canvasHeight(10)
            }
            var end = {
                x: origin.x - (2*length/3),
                y: origin.y + CanvasDimensionsService.canvasHeight(10)
            }
            
            drawTransformator(start, end);
        }
        
        function drawTransformator(start, end) {
            var height = CanvasDimensionsService.canvasHeight(15);
            start.y -= height/2;
            end.y -= height/2;
            DrawService.drawPath([start, end], '#00f', height);
        }
    
        function drawConnectionForSocket(socket) {
            var origin = {
                x: socket.x + socket.width / 2,
                y: socket.y + socket.height,
            };
        
            var boards = CanvasBoardService.getBoards();
            
            var socketConnectedBoard = boards[boards.length - 1];
            var destination = {};
            if (angular.isUndefined(socketConnectedBoard)) {
                destination = {
                    x: origin.x - vm.dimensions.width,
                    y: vm.dimensions.height - CanvasDimensionsService.canvasHeight(30)
                }
            } else {
                destination = socketConnectedBoard.pins[socketConnectedBoard.pins.length -1];
            }
            
            destination.used = true;
            
            drawSocketConnection(origin, destination);
        }
    
        function drawSocketConnection(origin, destination) {
            var rightOffset = vm.dimensions.width * 0.1;
            var bottomOffset = 5;
            
            var cord = [origin];
            
            if (destination.x > origin.x + rightOffset) {
                cord.push({
                    x: origin.x,
                    y: (vm.dimensions.height) * 0.6
                });
    
                cord.push({
                    x: destination.x + rightOffset,
                    y: (vm.dimensions.height) * 0.6
                });
                cord.push({
                    x: destination.x + rightOffset,
                    y: destination.y + bottomOffset
                });
            } else {
                cord.push({
                    x: origin.x,
                    y: destination.y + bottomOffset
                });
            }
            
            cord.push({
                x: destination.x,
                y: destination.y + bottomOffset
            });
    
            cord.push(destination);
        
            CanvasCordService.drawSocketCord(cord);
        }
    }
})();