(function () {
    'use strict';

    angular.module('it.directives').directive('installationLayer', installationLayer);

    function installationLayer() {
        return {
            restrict: 'E',
            templateUrl: 'js/templates/directives/installation-layer.html',
            controller: InstallationLayerController,
            controllerAs: 'vm',
            bindToController: true,
            scope: {
                index: '@?'
            },
        }
    }

    InstallationLayerController.$inject = [
        '$scope',
        '$element',
        '$window',
        '$timeout',
        'ConfigService',
        'CabService',
        'CanvasService',
    ];

    function InstallationLayerController(
        $scope,
        $element,
        $window,
        $timeout,
        ConfigService,
        CabService,
        CanvasService
    ) {

        var vm = this;
        
        vm.gridElement = null;
        vm.canvas = null;

        vm.canvasEdgeInsets = {
            top: 200,
            left: 100,
            bottom: 100,
            right: 100
        };

        // ---

        init();

        function init() {
            vm.gridElement = $element.closest('.Grid');
            vm.canvas = $element.find('canvas');
            
            if (!vm.index) {
                vm.index = ConfigService.selectedIndex();
            }

            CanvasService.init(vm.canvas[0], vm.canvasEdgeInsets, vm.index);

            itemListener();
            $scope.$broadcast('grid:updateItems');
            
            angular.element($window).bind('resize', refresh);
        }

        function itemListener() {
            $scope.$on('grid:updateItems', function() {
                $timeout(function () {
                    var powerSocketNeeded = ConfigService.configs.powerSocketNeeded();
                    if (powerSocketNeeded) {
                        if (CanvasService.getPowerSource() == 'battery') {
                            CanvasService.togglePowerSource();
                        }
                    }
                    refresh();
                });
            });
        }
        
        function refresh() {
            var width = $element.find('.InstallationLayer').outerWidth();
            var height = $element.find('.InstallationLayer').outerHeight();

            vm.canvas.attr({
                width: width + vm.canvasEdgeInsets.left + vm.canvasEdgeInsets.right,
                height: height + vm.canvasEdgeInsets.top + vm.canvasEdgeInsets.bottom,
            });
            vm.canvas.css({
                'margin-left': - vm.canvasEdgeInsets.left,
                'margin-top': - vm.canvasEdgeInsets.top
            });
    
            if (!CabService.rowsForConfig(vm.index)) return;
    
            var scaleHeight = vm.gridElement.find('.Droppable__Row').first().outerHeight()
            if (!scaleHeight) {
                scaleHeight = height / 2
            }
            
            var scale = {
                height: scaleHeight,
                width: width / CabService.rowsForConfig(vm.index)[0].cells.length,
            };
            CanvasService.setScale(scale, vm.index);
            CanvasService.redraw(vm.index);
        }

    }

})();