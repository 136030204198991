(function () {
    'use strict';

    angular.module('it.services').factory('CanvasService', CanvasService);

    CanvasService.$inject = [
        'ConfigService',
        'CabService',
        'DrawService',
        'CanvasDimensionsService',
        'CanvasSocketService',
        'CanvasBoardService',
        'CanvasBatteryService',
        'CanvasCordService'
    ];

    function CanvasService(
        ConfigService,
        CabService,
        DrawService,
        CanvasDimensionsService,
        CanvasSocketService,
        CanvasBoardService,
        CanvasBatteryService,
        CanvasCordService
    ) {

        var vm = this;

        vm.currentConfig = -1;
        vm.configs = [{},{},{}];
        
        vm.defaultConfig = {
            canvas: null,
            ctx: null,
            edgeInsets: null,
            innerDimensions: null,
            items: [],
            scale: {
                width: 0,
                height: 0,
            }
        };
        
        vm.borderWidth = 1;

        vm.socketType = 'inWallSocket';
        vm.powerSource = 'battery';

        return {
            init: init,
            currentConfig: currentConfig,
            setScale: setScale,
            togglePowerSource: togglePowerSource,
            getPowerSource: getPowerSource,
            setPowerSource: setPowerSource,
            toggleSocketType: toggleSocketType,
            getSocketType: getSocketType,
            setSocketType: setSocketType,
            redraw: redraw,
            reset: reset,
        }

        // ---

        init();
        
        
        // INIT
        
        function init(canvas, edgeInsets, configIndex) {
            if (configIndex < 0) return;
            
            vm.currentConfig = configIndex;
            var config = angular.copy(vm.defaultConfig);
            config.canvas = canvas;
            config.ctx = canvas.getContext('2d');
            config.edgeInsets = edgeInsets;
            vm.configs[configIndex] = config;
            updateDimensions(configIndex);
        }
        
        function currentConfig() {
            return vm.currentConfig;
        }
        
        // UPDATING DIMENSIONS
        
        function updateDimensions(index) {
            console.log('updateDimensions');
            if (index < 0) return;
            
            var config = vm.configs[index];

            if (angular.isUndefined(config) || angular.isUndefined(config.canvas)) return;
            
            config.innerDimensions = {
                width: config.canvas.width - config.edgeInsets.left - config.edgeInsets.right,
                height: config.canvas.height - config.edgeInsets.top - config.edgeInsets.bottom
            };
            config.ctx.resetTransform ? config.ctx.resetTransform() : config.ctx.setTransform(1, 0, 0, 1, 0, 0);
            config.ctx.translate(config.edgeInsets.left, config.edgeInsets.top);
    
            var body = ConfigService.config(index).get.body();
            if (angular.isObject(body)) {
                console.log(config.innerDimensions, body.dimensions.mm, config.scale);
                CanvasDimensionsService.init(config.innerDimensions, body.dimensions.mm, config.scale);
            }
            
            if (ConfigService.configs.powerSocketNeeded()) {
                vm.powerSource = 'power'
            }
        }
        
        function getItemsWithCoords(index) {
            var items = ConfigService.config(index).get.poweredItems();
            angular.forEach(items, function (item) {
                var coords = CabService.getCoordsForItemInConfig(item, index);
                item.cabCoords = coords;
            });
            vm.configs[index].items = items;
        }
        
        // DRAWING
    
        function redraw(index) {
            if (index < 0) return;
            var config = vm.configs[index];

            if (angular.isUndefined(config) || angular.isUndefined(config.edgeInsets)) return;
    
            ConfigService.selectConfig(index);
            
            DrawService.setContext(config.ctx);
            CanvasDimensionsService.setScale(config.scale);
            updateDimensions(index);
            DrawService.clearRect({
                x: -config.edgeInsets.left,
                y: -config.edgeInsets.top,
                width: config.canvas.width,
                height: config.canvas.height
            });
            
            getItemsWithCoords(index);
    
            CanvasBoardService.init(config.innerDimensions, config.items);
            var boards = CanvasBoardService.getBoards();
            ConfigService.selectedConfig().set.boardCount(boards.length);
            
            if (vm.powerSource == 'battery') {
                CanvasBatteryService.drawBatteries(config.items);
                boards = [];
            } else {
                CanvasBoardService.drawBoards();
            }
            
            CanvasCordService.drawBoardConnections(boards);
            
            var configCount = ConfigService.getConfigs().length;
            
            if (vm.powerSource != 'battery' || ConfigService.configs.powerSocketNeeded()) {
                if (index == configCount - 1) {
                    CanvasSocketService.init(config.innerDimensions);
                    CanvasSocketService.drawPowerSocket(vm.socketType);
                }
            }
            
            var fitting = ConfigService.config(index).get.fitting();
            if (fitting.powered) {
                var coords = CabService.getCoordsForItemInConfig(fitting, index);
                fitting.cabCoords = coords;
                CanvasSocketService.init(config.innerDimensions);
                CanvasSocketService.drawFittingSocket(fitting)
            }
            
            CanvasCordService.drawCabConnections(index, configCount, boards);
            
            if (vm.powerSource == 'battery') return;
            CanvasCordService.drawItemCords(config.items);
        }
        
        function reset() {
            vm.powerSource = 'battery';
            vm.socketType = 'inWallSocket';
            vm.configs = [{},{},{}];
        }
        
        function setScale(scale, index) {
            if (index < 0) return;
            vm.configs[index].scale = scale;
            CanvasDimensionsService.setScale(scale);
        }
        
        function togglePowerSource() {
            vm.powerSource = vm.powerSource == 'battery' ? 'power' : 'battery';
        }
        
        function getPowerSource() {
            return vm.powerSource;
        }
        
        function setPowerSource(powerSource) {
            vm.powerSource = powerSource;
        }
        
        function toggleSocketType() {
            vm.socketType = vm.socketType == 'powerSocket' ? 'inWallSocket' : 'powerSocket';
        }
        
        function getSocketType() {
            return  vm.socketType;
        }
        
        function setSocketType(socketType) {
            vm.socketType = socketType;
        }

    }

})();