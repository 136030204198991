(function () {
    'use strict';

    angular.module('it.directives').directive('toolbar', toolbar);

    function toolbar() {
        return {
            restrict: 'EA',
            templateUrl: 'js/templates/directives/toolbar.html',
            controller: ToolbarController,
            controllerAs: 'vm',
            bindToController: true
        }
    }

    ToolbarController.$inject = [
        'CabService',
        'CartService',
        'CanvasService',
        'CheckoutService',
        '$timeout',
        'NavService'
    ];

    function ToolbarController(
        CabService,
        CartService,
        CanvasService,
        CheckoutService,
        $timeout,
        NavService
        ) {

        var vm = this;

        vm.CabService = CabService;
        vm.CartService = CartService;
        vm.CanvasService = CanvasService;
        vm.NavService = NavService;
    
        vm.powerPlanningVisible = false;
        vm.setPowerSource = setPowerSource;
        vm.powerSource = powerSource;
        vm.installationPlannable = installationPlannable;
        vm.planInstallations = planInstallations;

        vm.powerSocketNeeded = powerSocketNeeded;

        function setPowerSource(source) {
            console.log("Source to set: " + source);
            $timeout(function() {
                // Wait for redraw has happend
                CheckoutService.updatePowerItemsForSource(source);
            }, 150);
        
            if (source == 'battery') {
                CanvasService.setPowerSource('battery');
                CanvasService.redraw(CanvasService.currentConfig());                
            } else {
                CanvasService.setPowerSource('power');
                CanvasService.setSocketType(source);
                CanvasService.redraw(CanvasService.currentConfig());                
            }
        }
    
        function powerSource() {
            var src = CanvasService.getPowerSource();
            if (src == 'battery') return src;
            else return CanvasService.getSocketType();
        }
    
        function togglePowerSource() {
            if (powerSocketNeeded() && CanvasService.getPowerSource() == 'power') {
                CanvasService.redraw(CanvasService.currentConfig());
            }
            else {            
                CanvasService.togglePowerSource();
                CanvasService.redraw(CanvasService.currentConfig());
            }        
        }

        function powerSocketNeeded() {
            var socketNeeded = vm.CartService.getPowerSupplyOnlyPoweredItems().length;
            return socketNeeded;
        }
    
        function toggleSocketType() {
            CanvasService.toggleSocketType();
            CanvasService.redraw(CanvasService.currentConfig());
        }
    
        function installationPlannable() {
            return vm.CartService.getPoweredItems().length || vm.CartService.powerSocketNeeded()
        }
    
        function planInstallations() {
            if (CabService.isBodyOpaque() || installationPlannable())
                CabService.toggleBodyOpacity();
        }
    }
})();