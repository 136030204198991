(function() {
    'use strict';

    angular.module('it-config').config(configure);

    configure.$inject = [
        '$httpProvider',
    ];

    function configure(
        $httpProvider
    ) {

        var API_PATH = '/api/';

        $httpProvider.interceptors.push(function($injector, $localStorage) {
            return {
                request: function(request) {
                    if (isApiUrl(request.url)) {
                        console.log("Sent request", request.url, request);
                        request.headers = getHeaders($localStorage);
                    }

                    return request;
                },
                response: function(response) {
                    if (isApiUrl(response.config.url)) {
                        console.log("Received response", response.config.url, response);
                        storeToken(response);
                    }

                    return response;
                },
                responseError: function(response) {
                    if (isNotLoggedIn(response.status)) {
                        delete $localStorage.token;
                        redirect(response);
                    }

                    return response;
                }
            };

            function getHeaders() {
                var headers = {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                };

                if ($localStorage.token) {
                    headers['Authorization'] = 'Bearer ' + $localStorage.token;
                }

                return headers;
            }

            function redirect(response) {
                var state = $injector.get('$state');

                if (state.current.name == 'confirm') return;

                if (response.config.url !== '/api/users' && response.config.method !== 'POST') {
                   state.go('login');
                }
            }

            function storeToken(response) {
                var token = response.headers('AuthToken');

                if ((!token || angular.isUndefined(token)) && response.data.data && response.data.data.token) {
                    token = response.data.data.token;
                }

                if (token) {
                    $localStorage.token = token;
                    console.log("Storing token:", token);
                }
            }

            function isApiUrl(url) {
                return url.indexOf(API_PATH) !== -1;
            }

            function isOkOrCreated(status) {
                return status == statusCode().ok || status == statusCode().created;
            }

            function isUnauthorized(status) {
                return status == statusCode().unauthorized;
            }

            function isBadRequest(status) {
                return status == statusCode().badRequest;
            }

            function isNotLoggedIn(status) {
                return isUnauthorized(status) || isBadRequest(status);
            }

            function statusCode() {
                return {
                    ok: 200,
                    created: 201,
                    badRequest: 400,
                    unauthorized: 401,
                    notFound: 404,
                    notAcceptable: 406,
                    unprocessable: 422
                }
            }

        });
    }

})();
