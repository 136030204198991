(function () {
    'use strict';
    
    angular.module('it.services').factory('CanvasCordService', CanvasCordService);
    
    CanvasCordService.$inject = [
        'DrawService',
        'CanvasBoardService',
        'CanvasDimensionsService'
    ];
    
    function CanvasCordService(
        DrawService,
        CanvasBoardService,
        CanvasDimensionsService
    ) {
        
         var vm = this;
        
        vm.cordWidth = function() {
            return CanvasDimensionsService.canvasWidth(4);
        };
        vm.offset = function() {
            return CanvasDimensionsService.canvasWidth(20);
        };
        
        return {
            drawItemCords: drawItemCords,
            drawBoardConnections: drawBoardConnections,
            drawSocketCord: drawSocketCord,
            drawCabConnections: drawCabConnections
        };
        
        function drawSocketCord(cord) {
            DrawService.drawPath(cord, '#00f', vm.cordWidth());
        }
        
        function drawBoardConnections(boards) {
            if (angular.isUndefined(boards)) return;
            if (boards.length <= 1) return;
            
            angular.forEach(boards, function(board, index) {
                if (!index) return;
                
                var origin = board.pins[0];
                var destination = boards[index - 1].pins[boards[index - 1].pins.length - 1];
                
                var path = [origin];
                path.push({
                    x: origin.x,
                    y: origin.y + vm.offset()
                });
                path.push({
                    x: destination.x,
                    y: destination.y + vm.offset()
                });
                path.push(destination);
                
                DrawService.drawPath(path, '#00f', vm.cordWidth());
                
                origin.used = true;
                destination.used = true;
            });
        }
        
        function drawItemCords(items) {
            angular.forEach(items, function (item) {
                var coords = CanvasDimensionsService.canvasCoordinateForItem(item);
                var pin = CanvasBoardService.getNextFreePin(coords);

                if (!pin) return;

                pin.used = true;
                
                var mirrored = (pin.x > coords.x);
                
                DrawService.drawJack(coords, mirrored);
                drawItemCord(coords, pin, mirrored);
            });
        }
    
        function drawItemCord(coords, pin, mirrored) {
            var offset = CanvasDimensionsService.canvasWidth(10);
            var plugOffset = (mirrored) ? offset / 2 : - offset / 2;
            var cord = [
                coords,
                {
                    x: coords.x + plugOffset,
                    y: coords.y,
                },
                {
                    x: coords.x + plugOffset,
                    y: coords.y + offset
                },
                {
                    x: pin.x,
                    y: coords.y + offset
                },
                pin
            ];
            DrawService.drawPath(cord, '#f00', vm.cordWidth());
        }
        
        function drawCabConnections(config, count, boards) {
            if (angular.isUndefined(boards)) return;
            if (boards.length == 0) return;
            
            if (config < count -1) {
                drawRightCabConnection(boards);
            }
            if (config != 0) {
                drawLeftCabConnection(boards);
            }
        }
        
        function drawRightCabConnection(boards) {
            var canvasSize = CanvasDimensionsService.canvasDimensions();
            var pins = boards[boards.length - 1].pins;
            var pin = pins[pins.length -1];
            pin.used = true;
            var cord = [
                pin,
                {
                    x: pin.x,
                    y: pin.y + vm.offset()
                },
                {
                    x: pin.x + canvasSize.width,
                    y: pin.y + vm.offset()
                }
            ];
            DrawService.drawPath(cord, '#00f', vm.cordWidth());
        }
        
        function drawLeftCabConnection(boards) {
            var canvasSize = CanvasDimensionsService.canvasDimensions();
            var pins = boards[0].pins;
            var pin = pins[0];
            pin.used = true;
            var cord = [
                pin,
                {
                    x: pin.x,
                    y: pin.y + vm.offset()
                },
                {
                    x: pin.x - canvasSize.width,
                    y: pin.y + vm.offset()
                }
            ];
            DrawService.drawPath(cord, '#00f', vm.cordWidth());
        }
    }
})();