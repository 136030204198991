(function () {
    'use strict';
    
    angular.module('it.services').factory('DrawService', DrawService);
    
    DrawService.$inject = [
        'CanvasDimensionsService'
    ];
    
    function DrawService(
        CanvasDimensionsService
    ) {
        
        var vm = this;
        
        vm.ctx = null;
        
        // Images
        vm.imagePath = '/img/symbols/';
        vm.imgFilenames = {
            powerSocket: 'powersocket.png',
            inWallSocket: 'inwallsocket.png',
            fittingPowerSocket: 'powersocket.png',
            powerPlug: 'powerplug.png',
            board: 'distributionboard.png',
        }
    
        // Drawing
        vm.borderWidth = CanvasDimensionsService.canvasWidth(2);
        
        return {
            setContext: setContext,
            clearRect: clearRect,
            drawImage: drawImage,
            drawPath: drawPath,
            drawJack: drawJack,
            drawBattery: drawBattery,
        }
        
        
        function setContext(context) {
            vm.ctx = context;
        }
        
        function clearRect(rect) {
            vm.ctx.clearRect(rect.x, rect.y, rect.width, rect.height);
        }
        
        function drawImage(name, rect) {
            drawImageInContext(name, rect, vm.ctx);
        }
        
        function drawImageInContext(name, rect, context) {
            var img = new Image();
            img.onload = function() {
                console.log("DRAW IMAGE", name);
                context.drawImage(img, rect.x, rect.y, rect.width, rect.height)
            };
            img.src = vm.imagePath + vm.imgFilenames[name];
        }
        
        function drawPath(path, color, width) {
            for (var i = 1; i < path.length; i++) {
                var origin = path[i-1];
                var destination = path[i];
                drawBorderedLine(origin,destination, color, width)
            }
        }
        
        function drawJack(coords, mirrored) {
            mirrored = mirrored || false;

            var plugWidth = CanvasDimensionsService.canvasWidth(10),
                caseWidth = CanvasDimensionsService.canvasWidth(15),
                plugHeight = CanvasDimensionsService.canvasWidth(4),
                caseHeight = CanvasDimensionsService.canvasWidth(8);
            if (mirrored) {
                plugWidth *= -1;
                caseWidth *= -1;
            }
            
            var plugend = {
                x: coords.x + plugWidth,
                y: coords.y
            };
            var jackend = {
                x: plugend.x + caseWidth,
                y: plugend.y
            };
            
            
            drawBorderedLine(coords, plugend, '#fff', plugHeight);
            drawBorderedLine(plugend, jackend, '#444', caseHeight);
        }
        
        function drawBattery(coords) {
            var plusHeight = CanvasDimensionsService.canvasWidth(4),
                plusWidth = CanvasDimensionsService.canvasWidth(10),
                batteryHeight = CanvasDimensionsService.canvasWidth(60),
                batteryWidth = CanvasDimensionsService.canvasWidth(27),
                batteryOffset = { 
                    x: CanvasDimensionsService.canvasWidth(30), 
                    y: CanvasDimensionsService.canvasWidth(-5),
                },
                lineHeight = CanvasDimensionsService.canvasWidth(1),
                lineOffset = CanvasDimensionsService.canvasWidth(10);
            
            coords = {
                x: coords.x + batteryOffset.x,
                y: coords.y + batteryOffset.y,
            };

            var plusEnd = {
                x: coords.x,
                y: coords.y + plusHeight,
            };
            var batteryEnd = {
                x: coords.x,
                y: plusEnd.y + batteryHeight
            };
            var lineStart = {
                x: coords.x - batteryWidth / 2,
                y: plusEnd.y + lineOffset,
            };
            var lineEnd = {
                x: lineStart.x + batteryWidth,
                y: lineStart.y,
            };
            
            drawBorderedLine(coords, plusEnd, '#ccc', plusWidth);
            drawBorderedLine(plusEnd, batteryEnd, '#303F9F', batteryWidth)
            drawLine(lineStart, lineEnd, '#FFC107', lineHeight);
        }
        
        // INTERNAL
        
        function drawBorderedLine(origin, destination, color, width) {
            drawLine(origin, destination, '#444', width + (vm.borderWidth * 2));
            drawLine(origin, destination, color, width);
        }
        
        function drawLine(origin, destination, color, width) {
            vm.ctx.lineWidth = width;
            vm.ctx.strokeStyle = color;
    
            vm.ctx.beginPath();
            vm.ctx.moveTo(origin.x, origin.y);
            vm.ctx.lineTo(destination.x, destination.y);
            vm.ctx.closePath();
            vm.ctx.stroke();
        }
    }
})();