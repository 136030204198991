(function () {
    'use strict';

    angular.module('it.services').factory('SwalService', SwalService);

    SwalService.$inject = [
        '$timeout',
    ];

    function SwalService($timeout) {

        var vm = this;

        vm.queue = [];

        return {
            push: push,
        }

        // ---

        function push(data) {
            vm.queue.push(data);

            if (vm.queue.length == 1) showNextInQueue();
        }

        function showNextInQueue() {
            if (!vm.queue.length) return;

            var data = vm.queue[0];

            // always pass data object
            if (angular.isString(data)) {
                data = { title: data };
            }

            swal(data, function (isConfirm) {

                if (isConfirm) {
                    if (angular.isFunction(data.onConfirm)) {
                        data.onConfirm();
                    }
                } else if (angular.isFunction(data.onCancel)) {
                    data.onCancel();
                }

                vm.queue.shift();

                $timeout(function() {
                    showNextInQueue();
                }, 500);
                
            });
        }
    }

})();