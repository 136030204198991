(function() {
    'use strict';
    
    angular.module('it.directives').directive('configOverview', configOverview);
    
    function configOverview() {
        return {
            restrict: 'E',
            templateUrl: 'js/templates/directives/order/config-overview.html',
            controller: ConfigOverviewController,
            controllerAs: 'vm',
            bindToController: true,
            scope: {
                index: '=',
                trans: '=',
            }
        }
    }
    
    ConfigOverviewController.$inject = [
        '$rootScope',
        'ConfigService',
        'CheckoutService'
    ];
    
    function ConfigOverviewController(
        $rootScope,
        ConfigService,
        CheckoutService
    ) {
        var vm = this;
        vm.config = ConfigService.config(vm.index);
        vm.cartData = vm.config.cart();
        vm.getNameSuffix = getNameSuffix();
        
        CheckoutService.updateConfig(vm.index);

        function getNameSuffix() {
            return ConfigService.getNameSuffix(vm.index);
        }
    }
})();
    