(function () {
    'use strict';

    angular.module('it.services').factory('WashstandService', WashstandService);

    WashstandService.$inject = [
        '$state',
        '$rootScope',
        '$timeout',
        'DragService',
        'GridHelper',
        'CartService',
        'CabService',
    ];

    function WashstandService(
        $state,
        $rootScope,
        $timeout,
        DragService,
        GridHelper,
        CartService,
        CabService) {

        var vm = this;

        vm.configs = [];
        vm.currentConfigIndex = null;

        vm.rows = null;

        vm.gridElement = null;

        init();

        return {
            init: init,
            addItem: addItem,
            removeItem: removeItem,
            moveItem: moveItem,
            rows: getRows,
            hoverCellsForItem: hoverCellsForItem,
            freeCellsForItem: freeCellsForItem,
            resetHoveredCells: resetHoveredCells,
            getCoordsForItem: getCoordsForItem,
            getCoordsForItemInConfig: getCoordsForItemInConfig,
            element: getGridElement,
            addConfig: addConfig,
            prepareForConfigAtPosition: prepareForConfigAtPosition,
            resetConfig: resetConfig,
            selectConfig: selectConfig,
            configs: getConfigs,
            clearGrid: clearGrid,
            placeItems: placeItems,
        }

        // ---

        function init() {
            // createGrid(292);
            watchBodyWidth();
            watchItemDataLoaded();
        }

        function watchBodyWidth() {
            $rootScope.$watch(function() {
                var body = CartService.getBodyFromConfigWithIndex(vm.currentConfigIndex);

                if (!body) return null;
                else return body.dimensions.units.width;
            }, function(newValue, oldValue) {
                if (!newValue ||
                    newValue === oldValue) return;
                
                createGrid(newValue);
            });
        }

        function watchItemDataLoaded() {
            $rootScope.$on('itemData:loaded', function(event, data) { 
                if (vm.rows) GridHelper.reloadItems(vm.rows);
            });
        }

        function createGrid(cols) {
            var rows = 2;

            // no grid present? -> create new
            if (!vm.rows) vm.rows = GridHelper.create(rows, cols);

            // grid already present? -> update
            else GridHelper.setWidth(vm.rows, cols);
        }

        function addConfig(index) {
            var body = CartService.getBodyFromConfigWithIndex(index),
                rows = GridHelper.create(2, body.dimensions.units.width);

            var config = { rows: rows };

            vm.configs[index] = config;
            return vm.configs.indexOf(config);
        }
    
        function prepareForConfigAtPosition(position) {
            if (position == 1) return;
            
            if (position == -1) {
                vm.configs.splice(0, 0, {});
            } else if (position == 0) {
                vm.configs.splice(1, 0, {});
            }
        }

        function selectConfig(index) {
            vm.rows = vm.configs[index].rows;
            vm.currentConfigIndex = index;
        }

        function resetConfig(index) {
            if (index >= vm.configs.length) return;
            vm.configs[index].rows = [];
        }

        function getConfigs() {
            return vm.configs;
        }

        function getRows() {
            return vm.rows;
        }

        function getRow(rowIndex) {
            return GridHelper.getRow(vm.rows, rowIndex);
        }

        function getCell(coords) {
            return GridHelper.getCell(vm.rows, coords);
        }

        function addItem(item, coords) {
            return GridHelper.addItem(vm.rows, item, coords);
        }

        function removeItem(item, coords) {
            GridHelper.removeItem(vm.rows, item, coords);
            CartService.removeItem(item);
        }

        function moveItem(item, dragCoords, dropCoords) {
            GridHelper.moveItem(vm.rows, item, dragCoords, dropCoords);
        }

        function freeCellsForItem(item, coords) {
            GridHelper.freeCellsForItem(vm.rows, item, coords);
        }

        function hoverCellsForItem(item, coords) {
            if (item.type != 'sink' &&
                item.type != 'faucet') return;

            GridHelper.hoverCellsForItem(vm.rows, item, coords);
            $rootScope.$apply();
        }

        function resetHoveredCells() {
            GridHelper.resetHoveredCells(vm.rows);
            $rootScope.$apply();
        }
        
        function getCoordsForItemInConfig(item, configIndex) {
            var rows = getRowsWithIndex(configIndex);
            return GridHelper.getCoordsForItem(rows, item);
        }

        function getCoordsForItem(item) {
            return GridHelper.getCoordsForItem(vm.rows, item);
        }

        function getGridElement() {
            return vm.gridElement;
        }
        
        function clearGrid() {
            GridHelper.clear(vm.rows);
        }

        function placeItems() {
            GridHelper.placeItems(vm.rows, CartService.getWashstandItems());
        }
    }

})(); 