(function() {
    'use strict';
    
    angular.module('it.services').factory('ConfigObjectService', ConfigObjectService);
    
    ConfigObjectService.$inject = [
        'ItemService'
    ];
    
    function ConfigObjectService(
        ItemService
    ) {
        var vm = this;

        vm.bodySizeUnits = [
            { name: 'XS', limit: 92 },
            { name: 'XL', limit: 192 },
            { name: 'XXL', limit: 292 }
        ];
        
        vm.bodyTypes = [
            { name: 'slim', itemIds: [ 1,2,3 ] },
            { name: 'euro', itemIds: [ 4,5 ] }
        ];
        
        return {
            priceForItems: priceForItems,
            groupItems: groupItems,
            groupOptions: groupOptions,
            config: config
        };
    
        function priceForItems(items) {
            var price = 0.0;
            angular.forEach(items, function(item) {
                if (angular.isDefined(item.amount)) {
                    price += item.price * item.amount;
                } else {
                    price += item.price;
                }
            });
            return price;
        }
        
        function groupItems(inItems) {
            var groupedItems = [];
            var items = angular.copy(inItems);
        
            angular.forEach(items, function(item) {
                var sameItems = groupedItems.filter(function(sameItem) {
                    return sameItem.item_no == item.item_no;
                });
            
                if (sameItems.length) {
                    var sameItem = sameItems[0];
                    sameItem.amount++;
                    if (sameItem.cartIndices.indexOf(item.cartIndex) == -1) {
                        sameItem.cartIndices.push(item.cartIndex);
                    }
                } else {
                    item.amount = 1;
                    item.cartIndices = [item.cartIndex];
                    if (angular.isUndefined(item.dependencies)) item.dependencies = {};
                    if (angular.isUndefined(item.dependencies.options)) item.dependencies.options = {};
                    if (angular.isUndefined(item.dependencies.options.items)) item.dependencies.options.items = [];
                    groupedItems.push(item);
                }
            });
            return groupedItems;
        }
    
        function groupOptions(configs) {
            var groupedOptions = [];
            var optionsRaw = [];

            angular.forEach(configs, function(config) {
                angular.forEach(config.data.items, function(item) {
                    item.dependencies = item.dependencies || {};
                    item.dependencies.options = item.dependencies.options || {};
                    item.dependencies.options.items = item.dependencies.options.items || [];
                    item.dependencies.option_min_count_slimline = item.dependencies.option_min_count_slimline || {};
                    item.dependencies.option_min_count_euroline = item.dependencies.option_min_count_euroline || {};
                    
                    var optIds = angular.copy(item.dependencies.options.items);
                    angular.forEach(optIds, function(optionId) {
                        var minCount = (isSlimline(config) ? item.dependencies.option_min_count_slimline[optionId] : item.dependencies.option_min_count_euroline[optionId]) || 0;
                        var option = {
                            id: optionId,
                            minCount: minCount.value || 0,
                        }

                        optionsRaw.push(option);
                    });
                });
            });
            
            if (optionsRaw.length > 0) {
                angular.forEach(optionsRaw, function(raw) {
                    var option = ItemService.getItem(raw.id);
                    
                    if (angular.isUndefined(option) || option == null) return;
                    
                    var sameOptions = groupedOptions.filter(function(sameOption) {
                        return sameOption.item_no == option.item_no;
                    });
                
                    if (sameOptions.length) {
                        var sameOption = sameOptions[0];
                        sameOption.amount += raw.minCount;
                        sameOption.minCount += raw.minCount;
                    } else {
                        option.amount = raw.minCount;
                        option.minCount = raw.minCount;
                        groupedOptions.push(option);
                    }
                });
            }

            return groupedOptions;
        }
        
        function isSlimline(config) {
            var bodyIds = vm.bodyTypes.filter(function(bodyType) {
                    return bodyType.name == 'slim';
                })[0].itemIds;

            return !! config.data.items.filter(function(item) {
                return bodyIds.indexOf(item.id) !== -1;
            }).length;
         }


        function config(index, data){
            return new Config(index, data);
        }
    
        function Config(index, data) {
            var conf = this;

            conf.index = angular.copy(index);
            conf.data = data;

            conf.get = {
                body: body,
                bodySize: bodySize,
                bodyType: bodyType,
                front: front,
                fitting: fitting,
                interior: interior,
                powerItems: powerItems,
                sinks: sinks,
                faucets: faucets,
                poweredItems: poweredItems,
                batteryPoweredItems: batteryPoweredItems,
                powerSupplyPoweredItems: powerSupplyPoweredItems,
                powerSupplyOnlyPoweredItems: powerSupplyOnlyPoweredItems,
                batteryOnlyPoweredItems: batteryOnlyPoweredItems,
                boardCount: boardCount,
                cables: cables,
            };

            conf.set = {
                boardCount: setBoardCount,
                cables: setCables,
            }

            conf.cart = cartData;
            conf.powerSocketNeeded = powerSocketNeeded;
        
            // GET
            function body() {
                return getFirstItemWithType('body');
            }

            function bodySize() {
                if (!body()) return null;

                var gridWidth = body().dimensions.units.width;
                var size;

                angular.forEach(vm.bodySizeUnits, function(bodySize) {
                    if (gridWidth >= bodySize.limit) size = bodySize.name;
                });

                return size;
            }

            function bodyType() {
                if (!body()) return null;

                var type = $.grep(vm.bodyTypes, function(bodyType) {
                    return bodyType.itemIds.indexOf(body().id) != -1;
                })[0];

                return type.name;
            }
        
            function front() {
                return getFirstItemWithType('front');
            }
        
            function fitting() {
                return getFirstItemWithType('fitting');
            }
        
            function interior() {
                return getItemsWithType('interior');
            }
        
            function powerItems() {
                return getItemsWithType('power');
            }
        
            function sinks() {
                return getItemsWithType('sink');
            }
        
            function faucets() {
                return getItemsWithType('faucet');
            }
        
            function poweredItems() {
                return getItemsWithProperty('powered', 1);
            }
        
            function batteryPoweredItems() {
                return getFiltered(poweredItems(), 'battery', 1);
            }
        
            function powerSupplyPoweredItems() {
                return getFiltered(poweredItems(), 'powerSupply', 1);
            }
        
            function batteryOnlyPoweredItems() {
                return getFiltered(getFiltered(poweredItems(), 'battery', 1), 'powerSupply', 0);
            }
        
            function powerSupplyOnlyPoweredItems() {
                return getFiltered(getFiltered(poweredItems(), 'powerSupply', 1), 'battery', 0);
            }
            
            function boardCount() {
                return conf.data.boards;
            }
            
            function cables() {
                return conf.data.cables
            }
            
            // SET
            function setBoardCount(count) {
                conf.data.boards = count
            }
            
            function setCables(cabels) {
                conf.data.cables = cabels
            }
        
            // CART
            function cartData() {
                var interior = cart_interior(),
                    price = cart_price(interior);
            
                return {
                    interior: interior,
                    price: price
                }
            }
        
            function cart_interior() {
                return groupItems(interior());
            }
        
            function cart_price(interior) {
                var price = priceForItems(interior);
                price += body().price;
                price += fitting().price;
                price += front().price;
                return price;
            }
        
            function powerSocketNeeded() {
                var items = powerSupplyOnlyPoweredItems();
                return items.length > 0;
            }
        
            // INTERNAL
            function getItemsWithType(type) {
                return getItemsWithProperty('type', type);
            }
        
            function getFirstItemWithType(type) {
                var items = getItemsWithType(type);
                return items.length ? items[0] : null;
            }
        
            function getItemsWithProperty(prop, value) {
                return getFiltered(conf.data.items, prop, value)
            }
        
            function getFiltered(items, prop, value) {
                var filteredItems = $.grep(items, function(item, index) {
                    return item[prop] == value;
                });
            
                return filteredItems;
            }
        }
    }
})();