(function() {
    'use strict';
    
    angular.module('it.services').factory('CanvasBatteryService', CanvasBatteryService);
    
    CanvasBatteryService.$inject = [
        'CanvasDimensionsService',
        'DrawService'
    ];
    
    function CanvasBatteryService(
        CanvasDimensionsService,
        DrawService
    ){
        var vm = this;
        
        return {
            drawBatteries: drawBatteries
        }
        
        function drawBatteries(items) {
            angular.forEach(items, function(item) {
               drawBatteryForItem(item);
            });
        }
        
        function drawBatteryForItem(item) {
            var coords = CanvasDimensionsService.canvasCoordinateForItem(item)
            DrawService.drawBattery(coords);
        }
    }
    
})();