(function () {
    'use strict';

    angular.module('it.controllers').controller('ConfirmController', ConfirmController);

    ConfirmController.$inject = [
        '$scope',
        '$rootScope',
        '$state',
        '$stateParams',
        '$q',
        'TranslationService',
        '$http'
    ];

    function ConfirmController(
        $scope,
        $rootScope,
        $state,
        $stateParams,
        $q,
        TranslationService,
        $http
        ) {

        // ---

        $scope.success = false;
        $scope.error = false;

        init();

        function init() {
            confirm();
        }

        function confirm() {
            $http.get('/api/confirm', { params: $stateParams }).then(function(response) {
                setStatus(response);
            }, function (response) {
                setStatus(response);
            });
        }

        function setStatus(response) {
            $scope.error = !isOkOrCreated(response.status);
            $scope.success = isOkOrCreated(response.status);
        }

        function isOkOrCreated(status) {
            return status == 200 || status == 201;
        }

    }
})();