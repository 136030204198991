(function () {
    'use strict';

    angular.module('it.controllers').controller('SignupController', SignupController);

    SignupController.$inject = [
        '$scope',
        '$rootScope',
        '$state',
        '$localStorage',
        '$q',
        'TranslationService',
        '$http'
    ];

    function SignupController(
        $scope,
        $rootScope,
        $state,
        $localStorage,
        $q,
        TranslationService,
        $http
        ) {

        // ---

        $scope.user = {};

        $scope.signup = signup;
        $scope.passwordConfirm = '';
        $scope.captcha = '';
        $scope.captchaWrong = false;
        $scope.success = false;
        $scope.error = false;

        function signup() {
            $scope.captchaWrong = ($scope.captcha != 14);
            
            if ($scope.captchaWrong) {
                return;
            }
            
            $http.post('/api/users', $scope.user ).then(function(response) {
                setStatus(response);
            }, function (response) {
                setStatus(response);
            });
        }

        function setStatus(response) {
            console.log(response);
            $scope.error = !isOkOrCreated(response.status);
            $scope.success = isOkOrCreated(response.status);
        }

        function isOkOrCreated(status) {
            return status == 200 || status == 201;
        }

    }
})();