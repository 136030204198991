(function () {
    'use strict';

    angular.module('it.directives').directive('cabinet', cabinet);

    function cabinet() {
        return {
            restrict: 'EA',
            templateUrl: 'js/templates/directives/cabinet.html',
            controller: CabController,
            controllerAs: 'vm',
            bindToController: true,
        }
    }

    CabController.$inject = [
        '$scope',
        '$rootScope',
        '$state',
        '$stateParams',
        '$timeout',
        '$element',
        'CabService',
        'CartService',
        'DragService',
        'ItemService',
        'GridHelper',
        'PresetService'
    ];

    function CabController(
        $scope,
        $rootScope,
        $state,
        $stateParams,
        $timeout,
        $element,
        CabService,
        CartService,
        DragService,
        ItemService,
        GridHelper,
        PresetService) {

        var vm = this;

        $rootScope.addItem = addItem;
        $rootScope.replaceItem = replaceItem;
        $rootScope.showBigDroppable = false;

        vm.getBodySize = CabService.getBodySize;
        vm.getBodyType = CabService.getBodyType;
        
        vm.CabService = CabService;
        vm.CartService = CartService;
        vm.ItemService = ItemService;

        vm.dragEnter = dragEnter;
        vm.dragStart = dragStart;
        vm.dragStop = dragStop;

        vm.draggableIsInGrid = 0;

        init();

        function init() {
            CabService.init($element);
            if (CartService.configs().length == 0) {
                $state.go('start');
            }

            watchItems();
        }

        function watchItems() {
            $scope.$watch(function() {
                return CartService.items();
            }, function(newValue, oldValue) {
                var fitting = CartService.getFitting();
                CabService.blockCellsForFitting(fitting);
            }, true);
        }

        function addItem(data, event, dropCoords) {
            if (DragService.clicked()) return;

            var item = data.item,
                dragCoords = data.coords;
            
            if (angular.isUndefined(item)) return;

            // assi workaround für doppeltes dropping
            if (item.type == 'interior') {
                // dragCoords not in data object? -> new item
                if (angular.isUndefined(dragCoords)) {
                    var itemCopy = angular.copy(item);
                    itemCopy.cartIndex = CartService.nextCartIndex();
                    if (CabService.addItem(itemCopy, dropCoords)) CartService.addItem(itemCopy); 
                } 

                // dragCoords in data object? -> move item
                else CabService.moveItem(item, dragCoords, dropCoords);
            }

            CabService.resetHoveredCells();
        }

        function replaceItem(data, event) {
            var item = data.item;
            var preset = data.preset;

            if (angular.isDefined(item)) {
                CabService.replaceItem(item);
            } else if (angular.isDefined(preset)) {
                $timeout(function() {
                    PresetService.choosePreset(preset, CartService.currentIndex());
                }, 100);
            }
        }

        function removeItem(data) {
            var item = data.item,
                coords = data.coords;
            CabService.removeItem(item, coords);
        }

        function dragEnter(data, event, coords) {
            if (!data) return;
            var item = data.item;
            if (angular.isDefined(item)) {
                CabService.hoverCellsForItem(item, coords);
            }
        }

        function dragStart(data) {
            var item = data.item,
                coords = data.coords;

            if (item.type != 'interior') return;

            CabService.freeCellsForItem(item, coords);
            GridHelper.onMove($element, CabService.rows());
        }

        function dragStop(data, event) {
            if (DragService.clicked()) return;

            var item = data.item,
                coords = data.coords;

            if (item.type != 'interior') return;

            if (!GridHelper.draggableIsInGrid()) removeItem(data);
        }
    }
})();