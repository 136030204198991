(function () {
    'use strict';

    angular.module('it.directives').directive('itemDetails', itemDetails);

    function itemDetails() {
        return {
            restrict: 'E',
            templateUrl: 'js/templates/directives/item-details.html',
            scope: {
                item: '='
            }
        }
    }

})();